.loaderWrapper,
.loaderOverlay
{
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 999;
}
.loaderWrapper{
.loaderOverlay {
    background-color: rgba(166, 174, 173, 0.15);
}
.loaderOverlay.rounded-10 {
    border-radius: 20px;
}
.spinner-border {
    z-index: 999;
    border-width: 9px;
}
.spinner-xl {
    width: 90px;
    height: 90px;
    animation-duration: 5s;
  }
.spinner-border.lg {
    width: 6rem;
    height: 6rem;
}
}
.loaderWrapper.innerPage {
height: calc(100vh - 60px)
}
.loaderWrapper.FullWindow {
    position: fixed;
}
.custom-position{ width:calc(100% - 240px) ; left: auto !important; right:0; top:0; bottom:0; position: fixed;z-index: 99999;}

@media(max-width:767px){
    .custom-position{ width:100% !important}
}