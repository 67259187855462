@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
body {
  font-size: 16px;
  color: #000;
  font-family: "Open Sans", sans-serif;
}
span.smallTextNew {
  font-size: 12px;
  display: block;
  line-height: 100%;
  letter-spacing: 1px;
  padding: 2px 0px;
}
.btn-primary {
  color: #fff;
  border-radius: 5px;
  background-color: #00a671;
  border-color: #00a671;
  font-size: 14px;
  padding: 0.7em 1.5em;
  &:hover {
    background-color: #027853;
    border-color: #027853;
  }
}
.dashboard-wraper {
  margin-left: 310px;
  background: #f0faf6;
}
.card {
  border: 0;
  background: #ffffff;
  border-radius: 15px;
}
.admin-logo {
  text-align: center;
  width: 100%;
  display: block;
  padding: 1em;
}
.admin-sidebar {
  width: 310px;
  background: #f0faf6;
  position: absolute;
  height: 100%;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 1em;
    li {
      transition: 0.26s all;
      border-radius: 10px;
      margin-top: 4px;
      a {
        color: #000;
        text-decoration: none;
        display: flex;
        padding: 0.6em 0;
        transition: 0.26s all;
        border-radius: 10px;
        align-items: center;
        width: 100%;
        i {
          margin-left: auto;
          margin-right: 0.5em;
        }
      }
      &:hover {
        color: #fff;
        background: #00a671;
      }
      &.active {
        color: #fff;
        background: #00a671;
        a {
          color: #fff;
          background: #00a671;
          img {
            filter: brightness(0) invert(1);
            transition: 0.26s all;
          }
          &:hover {
            color: #fff;
            background: #00a671;
            img {
              filter: brightness(0) invert(1);
              transition: 0.26s all;
            }
          }
          i {
            transform: rotate(90deg);
          }
        }
        .submenu {
          padding-left: 2em;
          display: block;
        }
      }
    }
    .submenu {
      padding-left: 2em;
      display: none;
    }
  }
}
.ck-editor .ck.ck-content {
  height: 200px;
  margin-bottom: 20px;
}
.color-primary {
  color: #00a671;
}
header.admin-header {
  position: absolute;
  right: 0;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
  }
}
.login-dropdown {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  .user-name {
    display: flex;
    align-items: center;
    img {
      width: 50px;
      height: 50px;
      border: 2px solid #00a671;
      object-fit: cover;
      border-radius: 100%;
    }
    h6 {
      font-size: 16px;
      font-weight: 400;
      margin-left: 0.5em;
      span {
        font-size: 14px;
        display: block;
        color: #686868;
      }
    }
  }
}
.content-wraper {
  background: #f5f5f5;
  padding: 1em;
  background: #f5f5f5;
  padding: 1em;
  height: calc(100vh - 85px);
  overflow: auto;
}
.table-wraper {
  background: #ffffff;
  border-radius: 15px;
  padding: 1em;
  .top-action {
    .input-group {
      background: #f8f8f8;
      border-radius: 8px;
      .input-group-text {
        background: transparent;
        border: none;
      }
      input {
        border: 0;
        background: #f8f8f8;
        border-radius: 8px;
        box-shadow: none !important;
        font-size: 14px;
      }
      button {
        background: #fff;
        border: none;
        color: #00a671 !important;
      }
    }
  }
  .table-responsive {
    table.table {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
      thead {
        background: #faf14b;
        border-radius: 15px 15px 0px 0px;
        color: #fff;
        th {
          color: black;
          font-weight: 600;
          white-space: nowrap;
        }
      }
      td {
        vertical-align: middle;
        font-size: 14px;
        color: #686868;
        a {
          font-size: 14px;
          text-decoration: none;
          color: #00a671;
        }
        p {
          font-size: 14px;
          margin: 0;
        }
        .dropdown-toggle {
          box-shadow: none;
          &.show {
            color: #00a671;
          }
        }
      }
    }
  }
}
h2.page-title {
  font-weight: 500;
  font-size: 32px;
  line-height: 10px;
  padding: 1em 0;
}
span.status-hold {
  color: #f89d2c;
}
span.status-Verified {
  color: #0066ff;
}
.status-active {
  color: #00a671;
}
.action-dropdown {
  outline: none;
  box-shadow: none;
  &::after {
    display: none;
  }
}
.ml-auto {
  margin-left: auto;
}
.pagination {
  .page-item.active .page-link {
    background-color: #faf14b;
    border-color: #faf14b;
    border-radius: 8px;
    color: #000 !important;
  }

  .page-link {
    color: #2a2a2a;
    background: #f8f8f8;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    margin: 5px;
  }
}
.dropdown-menu {
  color: #686868;
  font-size: 14px;
  padding: 0;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  border: 0;
  a {
    line-height: 30px;
    i {
      color: #00a671;
      margin-right: 0.4em;
    }
  }
}

.form-control {
  border: 1px solid black;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}
@font-face {
  font-family: "Aeonik-Regular";
  src: url("../src/assets/fonts/AeonikRegular/Aeonik-Regular-Web/Aeonik-Regular.woff");
  src: url("../src/assets/fonts/AeonikRegular/Aeonik-Regular-Web/Aeonik-Regular.woff2");
  font-weight: 300;
  font-style: normal;
}
label {
  // font-size: 14px;
  // color: #686868;
  font-family: "Aeonik-Regular" !important;
  // font-size: 1vw;
  color: #0e0e0e !important;
  text-transform: none;
  font-weight: 700;
  font-style: normal;
  // line-height: 1.25;
  // letter-spacing: 0px;
  // margin-bottom: 0vw;
  // margin-bottom: 0.5em;
}

// body {
//     font-family: 'YourFontName', sans-serif; /* Use your font for specific elements */
// }

.drap-drop {
  background: #ffffff;
  border: 1px solid #00a671;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  text-align: center;
  height: 143px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .structure {
    color: #686868;
    font-size: 14px;
    i {
      width: 35px;
      height: 35px;
      background: #f0faf6;
      line-height: 35px;
      border-radius: 100%;
      text-align: center;
      margin-right: 0.8em;
    }
  }
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
  p {
    background: #ffffff;
    border: 1px solid #686868;
    border-radius: 40px;
    font-size: 12px;
    padding: 0.3em 1em;
    margin: 2px;
  }
}

.from-to {
  display: flex;
  align-items: center;
  background: #fff;
  input {
    border: 1px solid #00a671 !important;
    margin: 6px;
    background: #fff !important;
  }
}
.user-img-box {
  img {
    max-width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid #00a671;
    object-fit: cover;
  }
}
.dasdboard-card {
  .card {
    .icon-outer {
      background: #f0faf6;
      width: 60px;
      height: 60px;
      padding: 0.5em;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
    }
    h5 {
      font-weight: 600;
      font-size: 1.6em;
    }
    p {
      color: #686868;
    }
  }
}
//For payment buttons
.payment-btn {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
//Notification css
.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.notification-list {
  text-align: left;
}
.notification-list.no-notifications {
  padding: 5px;
}
//   input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   -webkit-box-shadow: 0 0 0px 1000px white inset;
// //   transition: background-color 5000s ease-in-out 0s;
// }

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #f4f4f4 inset !important;
  -webkit-text-fill-color: black !important;
}

body {
  font-family: "Aeonik", sans-serif; /* Use your font for specific elements */
}

.blackBackground {
  background-color: #0c0d0a;
  color: #f5f5f5;

  h3 {
    color: #f5f5f5 !important;
  }
  .blackText {
    color: #0c0d0a !important;
  }
}
.blackText {
  color: #0c0d0a !important;
}

.maincontent {
  font-weight: bold;
}
.maincontent1 {
  font-weight: bold;
  font-size: 18px;
}
.text {
  font-weight: bold;
  font-size: 16px;
}
.textcolor {
  color: #3a9bdc;
  font-size: 18px;
  font-weight: 600;
}
.textcolor1 {
  color: #3a9bdc;
}
.textcolor2 {
  color: #3a9bdc;
  font-size: 15px;
  margin-top: 18px;
}
.textcontent {
  font-weight: bold;
}
.textcontent2 {
  font-weight: bold;
  font-size: 16px;
  padding: 0px 52px;
}
.textcontent3 {
  padding: 0px 52px;
}
.paratext {
  padding: 0px 52px;
}
.parabold {
  font-weight: bold;
}
