* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Prime React

//theme
@import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
@import "primereact/resources/primereact.min.css";

//icons
@import "primeicons/primeicons.css";

// custom colors

// $primary: #00a671;
$primary: #faf14b;
$light: #ffffff;
$dark: #262929;
$grey: #686868;
$lgrey: #cccccc;
$xlight: #f0faf6;
$xlgrey: #f8f8f8;
$yellow: #ffac00;

// Common Mixins
@mixin itemPosition($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin roundShape($width, $height, $bgColor) {
  width: $width;
  height: $height;
  border-radius: 50%;
  background-color: $bgColor;
  object-fit: cover;
  @include itemPosition(center, center);
}

@mixin formFields {
  height: 46px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  background-color: rgba($color: $input, $alpha: 0.15);
  font-weight: 500;
  border: 1px solid $inputBorder;

  &:focus {
    box-shadow: none;
  }
}

// common Btns

.btn-main {
  background-color: $primary;
  padding: 10px 30px;
  border-radius: 10px;
  color: $light;
  font-weight: 700;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 0.9;
    color: $xlight;
  }
}
.btn-main-outline {
  background-color: $xlight;
  padding: 10px 30px;
  border-radius: 10px;
  color: $primary;
  font-weight: 700;
  border: 1px solid $primary;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 0.9;
    color: $light;
    background-color: $primary;
  }
}
.btn-white-outline {
  background-color: $light;
  padding: 10px 30px;
  border-radius: 10px;
  color: $dark;
  font-weight: 700;
  border: 1px solid $grey;
  &:focus {
    box-shadow: none;
  }
  &:hover {
    opacity: 0.9;
    color: $light;
    background-color: $grey;
  }
}

// common Classes
body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
  // background-color: #0c0d0a
}

// body {
//   font-family: "Open Sans", sans-serif;
//   background-image: url("../public/assets/pattern.png");
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   }
.centerMid {
  @include itemPosition(center, center);
}
h1 {
  font-size: 40px;
  color: $dark;
  font-family: "Poppins", sans-serif;
  span {
    font-weight: 400;
  }
}
h3 {
  font-size: 25px;
  color: $dark;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  span {
    font-weight: 400;
  }
}
p {
  font-size: 16px;
  span {
    font-weight: 500;
  }
}
.inputField {
  margin: 15px 0;
  label {
    color: black;
    margin-bottom: 10px;
  }
  .inputBox {
    position: relative;
    border: 1px solid #0c0d0a;
    border-radius: 7px;
    input {
      background-color: #f4f4f4 !important;
      border-radius: 7px;
      border: none;
      padding: 7px 40px;
      &:focus {
        box-shadow: none;
      }
    }
    i {
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: #0c0d0a;
    }
    i.seen {
      position: absolute;
      right: 15px;
      left: auto;
      top: 50%;
      transform: translateY(-50%);
      color: $dark;
    }
    &.countryCodeBox {
      display: flex;
      .countryCode2 {
        width: 40px !important;
        padding-left: 5px !important;
      }
      .countryCode {
        border-right: 1px solid $grey;
        width: 60px;
        border-radius: 7px 0 0 7px;
      }
      .countryCode1 {
        text-align: center;
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 20px;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}
.inputField-inline {
  @include itemPosition(center, flex-start);
  margin: 8px 0;
  label {
    color: $dark;
    margin-bottom: 0;
    font-weight: 600;
    width: 188px;
    span {
      font-weight: 300;
    }
  }
  .inputBox {
    @include itemPosition(center, flex-start);
    gap: 10px;
    input {
      flex: 1;
      background-color: $light;
      border-radius: 7px;
      border: 1px solid black;
      max-width: 300px;
      &:focus {
        box-shadow: none;
        border: 1px solid $primary;
      }
      &.disabled {
        border: none;
        background-color: $xlgrey;
        padding-left: 0;
      }
    }
    i {
      color: #0c0d0a;
    }
    .stars {
      i {
        color: $yellow;
      }
    }
    textarea {
      border: none;
      border-radius: 8px;
      border: 1px solid black;
      &:focus {
        box-shadow: none;
      }
    }
  }
  &.bgLight {
    background-color: $light;
    // padding: 5px;
    max-width: 402px;
    border-radius: 8px;
    .inputBox {
      input {
        &.plane {
          border: none;
          padding-left: 0;
        }
      }
    }
  }
}
.buttonsRow {
  margin: 15px 0;
  @include itemPosition(center, space-between);
  .navBtn {
    a {
      text-decoration: none;
      color: $primary;
    }
  }
  .btnGroups {
    @include itemPosition(center, flex-end);
    gap: 10px;
    padding: 0;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: $dark;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #faf14b;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #faf14b;
}
// Search and Filter tools
.toolsContainer {
  .searchBox {
    position: relative;
    width: 100%;
    input {
      padding-left: 50px;
      border-radius: 25px;
      height: 50px;
      &:focus {
        box-shadow: none;
      }
    }
    input::placeholder {
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Body/B2 */
      font-family: "Aeonik-Regular";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* Firefox */
      @media screen and (max-width: 576px) {
        // font-family: Aeonik;
        color: var(--Brand-black, #0e0e0e);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    i {
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      color: $dark;
    }
    .serchResults {
      width: 100%;
      height: auto;
      background-color: $light;
      margin-top: 10px;
      border-radius: 15px;
      position: absolute;
      top: 50px;
      overflow: auto;
      z-index: 4;
      // display: none;

      .result {
        @include itemPosition(center, flex-start);
        gap: 10px;
        padding: 10px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 0.5px solid $grey;
        }
        .image {
          width: 50px;
          height: 50px;
          border-radius: 5px;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .roundImage {
          @include roundShape(55px, 55px, transparent);
          // padding: 10px;
          img {
            width: 100%;
            @include roundShape(50px, 50px, transparent);
          }
        }
        .text {
          p {
            color: $dark;
          }
        }
      }
    }
  }
  .filters {
    .card {
      position: relative;
      border-radius: 12px;
      width: 100%;
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        color: $primary;
      }
      .p-multiselect {
        padding-left: 20px;
        border: none;
        color: $primary;
        text-align: left;
        border-radius: 12px;
      }
    }
  }
}
.ProfileDesign {
  @media screen and (max-width: 576px) {
    margin: 0px 10px;
  }
  position: relative;
  background-color: $xlight;
  border-radius: 15px;
  padding: 15px;
  img.mainImage {
    position: absolute;
    // bottom: -40px;
    max-width: 300px;
    max-height: 140px;
  }
  p {
    i {
      color: #0c0d0a;
    }
  }
}
// starContent Cards
.starContent {
  // background-image: url("../public/assets/starsBackground.png");
  background-color: #faf14b;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 50px;
  border-radius: 20px;
  position: relative;
  margin: 12px 0;
  height: 290px;
  h4 {
    font-family: "Aeonik-Bold" !important;
  }
  p {
    font-family: "Aeonik-Regular" !important;
    color: #0c0d0a !important;
  }
  .profile {
    @include roundShape(120px, 120px, transparent);
    padding: 7px;
    margin: auto;
    img {
      width: 100%;
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
      // objf
    }
  }
  .text {
    h4 {
      font-weight: 600;
    }
    p {
      i {
        color: #0c0d0a;
      }
      color: $grey;
      span {
        color: $dark;
        font-weight: 600;
      }
    }
  }
  .nextBtn {
    @include roundShape(40px, 40px, black);
    color: $light;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
  &:hover {
    // background-image: url("../public/assets/starsBackground_hover.png");
    background-color: #faf14b;
    .nextBtn {
      display: flex;
    }
  }
}
// Paination
nav.paginationRow {
  ul.pagination {
    justify-content: center;
    li.page-item {
      a.page-link {
        background: $xlgrey;
        color: $grey;
        border-radius: 10px;
        margin: 0 5px;
      }

      &.active {
        a.page-link {
          border: 1px solid #faf14b;
          background: #faf14b;
          color: $light;
        }
      }
    }
  }
}
// Page Direction
.direction {
  @include itemPosition(center, space-between);
  p {
    color: #f5f5f5;
    font-family: "Aeonik-Regular";
    i {
      display: inline-block;
      margin: 0 7px;
      color: $grey;
    }
    span {
      color: #f5f5f5;
      display: inline-block;
      margin-left: 8px;
    }
  }
}
.review {
  @include itemPosition(center, flex-start);
  .star {
    i {
      color: $yellow;
    }
  }
  p {
    color: $dark;
    font-weight: 600;
  }
}
.mw-nornal {
  max-width: 350px;
  margin: auto;
}

.modal-dialog {
  .module {
    padding: 0px 0px !important;
  }
  .modal-body {
    position: relative;
    text-align: center;
    padding: 50px 0;
    .close {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }
    p {
      color: $grey;
    }
    h4 {
      font-size: 16px;
    }
    .otpBox {
      @include itemPosition(center, center);
      max-width: 400px;
      gap: 15px;
      margin: 15px auto;

      input {
        border: 1px solid black;
        font-weight: 600;
        background-color: rgba($color: $lgrey, $alpha: 0.1);
        text-align: center;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

// use Image
.tippingImage {
  .about {
    // ../../assets/images/Images.png
    // background-image: url("../src/assets/images/HomeImage.jpg");
    height: fit-content;
    background-color: #f4f4f4;
    padding: 65px;
    width: 98%;
    border-radius: 20px;
    @media only screen and (max-width: 576px) {
      padding: 24px 20px;
      padding-bottom: 9px;
    }
    .inputSearchBar {
      display: flex;
      @media only screen and (max-width: 768px) {
        display: contents;
      }
    }
    .welcomText {
      display: flex;
      align-items: center;
      padding-top: 50px;
      @media screen and (max-width: 576px) {
        padding-top: 12px;
      }
    }

    .inputField {
      .inputBox {
        border-radius: 40px;
        border: 1px solid black !important;
        input {
          border-radius: 40px;
        }
      }
      @media only screen and (max-width: 576px) {
        padding-top: 20px;
      }
    }
    .mainSearchbutton {
      margin-left: 10px;
      border-radius: 40px;
      height: 60px;
      width: 110px;
      background-color: #0e0e0e !important;
      h2 {
        margin-bottom: 0px !important;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 0px;
        width: 100%;
      }
      @media only screen and (max-width: 576px) {
        margin-left: 0px;
        width: 100%;
      }
    }
    // padding:2px;
    img {
      width: fit-content;
      @media only screen and (min-width: 768px) and (max-width: 1267px) {
        width: 250px;
      }
      @media only screen and (max-width: 768px) {
        margin-top: 50px;
        width: fit-content;
      }
      @media only screen and (max-width: 576px) {
        margin-top: 20px;
        display: none;
      }
    }

    .tippingTime {
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Headers/Hero H1 */
      font-family: "Spoof Black";

      font-size: 6rem;
      font-style: normal;
      font-weight: 800;
      line-height: 80%; /* 89.6px */
      letter-spacing: -5.6px;
      text-transform: uppercase;
      margin-bottom: -0.4rem !important;
      position: relative;
      @media only screen and (max-width: 576px) {
        font-size: 54px;
      }
      @media only screen and (max-width: 576px) {
        font-size: 54px;
        margin-bottom: 0.2rem !important;
        font-size: 35px;
        font-style: normal;
        font-weight: 800;
        /* 34px */
        letter-spacing: -2px;
        text-transform: uppercase;
        @supports (-moz-appearance: none) {
          font-size: 38px;
        }
      }
      @supports (-webkit-min-device-pixel-ratio: 0) and
        (not (overflow: -webkit-marquee)) {
        /* Safari-specific styles */
        svg {
          /* Your Safari-specific styles here */
          right: -20px !important;
          /* Add other styles specific to Safari */
        }
      }
      //   @supports (-webkit-appearance: none) {
      //     /* Specific styles for Safari */

      //     svg {
      //         /* Your Safari-specific styles here */
      //         position: static !important;
      //         margin-right: -20px !important;
      //         /* Add other styles specific to Safari */
      //     }
      // }

      svg {
        width: 102.273px !important;
        height: 105.273px !important;
        transform: rotate(9.553deg) !important;
        position: absolute;
        @supports (-webkit-min-device-pixel-ratio: 0) {
          /* Specific styles for Safari */
          /* This will only apply to Safari which supports -webkit- prefix */
          margin-right: -50% !important;
        }
        top: 209px;
        // left: 439px;
        top: -11px;
        z-index: 1;
        right: 33.5%;
        @supports (-moz-appearance: none) {
          right: 31.5%;
        }

        // @supports (-moz-appearance:none) {
        //   right: 31.9%;
        // }
        // @-moz-document url-prefix() {
        //   right: 31.9%;
        // }
        @media screen and (max-width: 1680px) {
          right: 33.5%;
          @supports (-moz-appearance: none) {
            right: 31.5%;
          }
        }
        @media screen and (max-width: 1440px) {
          right: 33.5%;
          @supports (-moz-appearance: none) {
            right: 30.5%;
          }
        }
        @media screen and (max-width: 1366px) {
          right: 29%;
          @supports (-moz-appearance: none) {
            right: 26%;
          }
        }

        @media screen and (max-width: 1280px) {
          right: 23.5%;
          @supports (-moz-appearance: none) {
            right: 20%;
          }
        }

        @media screen and (max-width: 1152px) {
          right: 13%;
          @supports (-moz-appearance: none) {
            right: 9%;
          }
        }
        @media screen and (max-width: 1024px) {
          right: 0%;
          @supports (-moz-appearance: none) {
            right: -5%;
          }
        }

        @media screen and (max-width: 475px) {
          @media not all and (min-resolution: 0.001dpcm) {
            position: static !important;
          }

          //       @media not all and (min-resolution:.001dpcm)
          // { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
          //   margin-left: 500px !important;
          // }}

          // }

          // left: 184px;
          // width: 46.91px !important;
          // height: 46.91px !important;
          // top: -7px;
          left: 183px;
          // -webkit-left: 196px !important;
          width: 52.91px !important;
          height: 51.77px !important;
          top: -15px;
        }

        @media (min-width: 1921px) {
          left: 439px;
        }

        //   @media screen and (max-width: 1024px) {

        //       right: 1.1%;

        // }
      }
    }
    .favbartender {
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Body/B1 */
      font-family: "Aeonik-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      @media (max-width: 576px) {
        color: var(--Brand-black, #0e0e0e);
        // font-family: Aeonik;
        flex: 1 0 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 250;
        line-height: normal;
      }
    }
  }
}

//How it works new scss

.itWorks {
  // border:1px solid #f5f5f5;
  // column-gap: 25px;
  //  .whiteBorder{
  //   width:82%

  //  }
  row-gap: 32px;
  h3 {
    font-size: 40px;
    @media screen and (max-width: 576px) {
      flex: 1 0 0;
      color: var(--Brand-White, #f4f4f4);
      /* Mobile/HEADERS/H1 */
      // font-family: Spoof Trial;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 90%; /* 21.6px */
      letter-spacing: -1.2px;
      text-transform: uppercase;
      padding-bottom: 24px;
    }
  }
  margin: 32px 3px 0 3px;
  .btn-height {
    h2 {
      font-weight: 800;
      font-family: "Spoof Black";
    }
    height: 100%;
  }
  hr {
    background-color: #f5f5f5;
  }
  .whiteBorder {
    border: 1px solid var(--Brand-White, #f4f4f4);
    border-radius: 20px;
  }
  .findWay {
    padding: 35px 0px;
    @media screen and (max-width: 576px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .mobileBorderTop {
      @media only screen and (max-width: 576px) {
        border-top: 1px solid #f4f4f4 !important;
      }
    }
  }
  .borderNone {
    border: 0px !important;
  }
  .steps {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 768px) {
      justify-content: start;
      p {
        margin-top: 10px !important;
      }
    }
    .nav-link {
      padding: 0px !important;
    }
  }
}
.getstarted {
  @media only screen and (max-width: 768px) {
    border-left: 0px;
    border-top: 1px solid rgba(244, 244, 244, 0.2);
  }

  h3 {
    color: white;
    font-family: "Spoof Black";
  }
  p {
    font-size: 20px;
    font-family: "Aeonik-Regular";
  }
  .p2 {
    margin-left: 18px;
  }
  border-left: 1px solid rgba(244, 244, 244, 0.2);
}

// Running text
.margueTool {
  margin-top: 32px;
  background-color: #faf14b;
  p {
    font-size: large;
    font-weight: 600;
    font-family: "Aeonik-Bold";
    margin: 0;
  }
  marquee {
    align-items: center;
    display: flex;
  }
}

//ends here

// search Pub
.searchPub {
  margin: 32px 10px 0 10px;
  border-radius: 20px;
  background-color: #f5f5f5;
  padding: 50px 0px 50px 0px;
  row-gap: 20px;
  @media screen and (max-width: 576px) {
    padding: 24px 0px 24px 0px;
  }
  h3 {
    font-weight: 800;
    font-family: "Spoof Black";
  }
  img {
    height: 300px;
    border-radius: 20px;
  }
  .btn-height {
    h2 {
      font-weight: 800;
      font-family: "Spoof Black";
    }
    height: 100%;
  }
}

.staffSearch {
  @media only screen and (max-width: 768px) {
    .getstarted {
      border-left: 0px !important;
    }
  }
  .btn-main {
    width: 100%;
  }
  h3 {
    font-weight: 800;
  }

  .findStaff {
    @media only screen and (max-width: 768px) {
      border-bottom: 1px solid #f5f5f5 !important;
    }
  }
  .profileview {
    border-left: 1px solid rgba(244, 244, 244, 0.2);
  }
  .profileview {
    gap: 16px;
    align-items: center;
    @media only screen and (min-width: 768px) {
      align-items: auto;
    }
    @media only screen and (max-width: 767px) {
      display: inline-block !important;
      padding-top: 20px;
    }
    @media only screen and (max-width: 767px) {
      .nav-link {
        width: 47%;
        display: inline-block;
        text-align: center;
        margin: 0 auto;
      }
    }
    @media only screen and (max-width: 576px) {
      .nav-link {
        width: 32%;
        // display: inline-block;
        // text-align: center;
        // margin: 0 auto;
      }
    }

    .image {
      /* align-items: center; */
      // display: flex;
      justify-content: center;
      padding: 0px 7px;
      flex-wrap: wrap;

      @media only screen and (max-width: 767px) {
        // width: 50%;
        display: inline-block;
        padding: 0px 30px;

        image {
          padding: 0px;
        }
      }
      @media only screen and (max-width: 576px) {
        // width: 50%;
        display: inline-block;
        padding: 0px 10px;

        image {
          padding: 0px;
        }
      }

      img {
        @media only screen and (max-width: 767px) {
          // display: inline-block !important;
          width: 100px;
          height: 100px;
        }
        @media only screen and (max-width: 576px) {
          // display: inline-block !important;
          width: 60px;
          height: 60px;
        }
        width: 75px;
        height: 75px;
        margin-left: 8px;
        border-radius: 50%;
        display: flex;
        object-fit: cover;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .buttonHome {
      margin-top: 20px !important;
    }
  }
}

//Create Free Account div scss
.freeAccount {
  background-color: #faf14b;
  margin: 32px 10px 0 10px;
  padding: 50px 0px 50px 0px;
  border-radius: 20px;
  row-gap: 20px;
  @media screen and (max-width: 576px) {
    row-gap: 24px;
  }
  .btn-main {
    background-color: #f5f5f5;
    height: 170px;
    @media screen and (max-width: 576px) {
      height: 90px;
    }
  }
  h2 {
    font-weight: 900;
    font-family: "Spoof Black";
    @media screen and (max-width: 576px) {
      margin-bottom: 0px;
    }
  }
  h3 {
    font-weight: 800;
    font-size: 40px;
    font-style: normal;
    font-family: "Spoof Black";
  }
}
.w-20 {
  width: 20%;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}

// ******************** Pages  ***********************
// HomePage
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}
.homePage {
  background-color: #0c0d0a;
  // padding-bottom: 32px;
  .headerSection {
    padding-top: 8px;

    .header {
      max-width: 100%;
      // margin: 20px auto 20px;
      background: transparent;
      border-radius: 20px;
      padding: 0px;
      padding-bottom: 40px;
      @media screen and (max-width: 576px) {
        padding-bottom: 14px;
      }

      border-bottom: none !important;
      // box-shadow: 0px 8px 25px rgba(0, 166, 113, 0.21);
      .navbar-brand {
        img {
          width: 111.929px;
          height: 50px;
          margin-left: -12px;
          @media only screen and (max-width: 1200px) {
            margin-left: 0px;
          }
          @media only screen and (max-width: 576px) {
            height: fit-content;
            width: 60px;
          }
        }
      }
      ul.navbar-nav {
        gap: 32px;
        @media only screen and (max-width: 1200px) {
          gap: 0px;
        }
        li.nav-item {
          @media only screen and (max-width: 1200px) {
            padding: 0 5px;
          }
          @media only screen and (max-width: 576px) {
            padding: 2px 15px;
          }

          a {
            text-decoration: none;
            font-family: "Aeonik-Regular";
            color: var(--Brand-White, #f4f4f4);
            /* Buttons/Buttons */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 171.429% */

            &:hover {
              color: #faf14b;
            }
          }
          a.highlighted {
            color: #f4f4f4 !important;
            font-weight: 500;
            &:hover {
              color: #faf14b !important;
            }
          }
        }
      }
    }
    .headerContent {
      .content {
        max-width: 550px;
        margin: auto;
        p {
          font-size: 20px;
        }
      }
    }
  }
  .howitworksSection {
    padding: 70px 0 0;
    .howitworksOptions {
      @include itemPosition(center, center);
      width: 100%;
      .opt {
        min-width: 300px;
        height: 150px;
        text-align: center;
        @include itemPosition(center, center);
        color: $light;
        img {
          width: 80px;
        }
        P {
          margin: 10px 0 0 0;
        }
      }
      .option {
        background-color: $primary;
        border-radius: 15px;
        &:hover {
          background-color: rgba($color: $primary, $alpha: 0.7);
        }
      }
      // .option2 {
      //   border-radius: 15px;
      // }
      // .option3 {
      //   background-color: rgba($color: $primary, $alpha: 0.4);
      //   border-radius: 15px;
      // }
    }
    .howitworkscontent {
      padding: 70px 0;
      max-width: 1300px;
      margin: auto;
      img {
        width: 75%;
      }
      .textBox {
        position: relative;
        .singleLine {
          height: calc(100% - 10px);
          width: 2px;
          background-color: $primary;
          position: absolute;
          left: 14px;
          top: 5px;
          z-index: 0;
        }
        .textRow {
          @include itemPosition(center, flex-start);
          gap: 20px;
          margin: 60px 0;
          .bulletBox {
            @include roundShape(30px, 30px, $primary);
            color: $light;
            z-index: 3;
          }
          .point {
            flex: 1;
            p {
              font-size: 25px;
              color: $grey;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    img {
      width: 100%;
    }
  }

  .faqSection {
    // max-width: 100;
    // background-color: #f5f5f5;
    margin: auto;
    padding: 0px 20px;
    .askedQuestions {
      border-radius: 20px;
      padding-top: 25px;
      padding-bottom: 5px;
      margin-top: 32px;
    }

    img {
      width: 100%;
    }
    .accordion-item {
      background-color: #f4f4f4;
      border: 1px solid #f4f4f4;
      border-radius: 10px;
      margin: 20px 0;
    }
    .accordion-button {
      background: #f4f4f4;
      border-radius: 10px;
      font-family: "Aeonik-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 125% */
      border-bottom: 1px solid lightgray;
      border-radius: 0;
      &:focus {
        box-shadow: none;
      }

      @media screen and (max-width: 576px) {
        color: var(--Brand-black, #0e0e0e);
        align-self: stretch;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 18px */
      }
    }
    .accordion-body {
      background-color: #f4f4f4;
      align-self: stretch;
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Body/B2 */
      font-family: "Aeonik-Regular";
      font-size: 20px;
      @media screen and (max-width: 576px) {
        font-size: 16px;
      }
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 30px */
    }
    .accordion-button:not(.collapsed) {
      color: #0c0d0a;
    }

    h3 {
      font-family: "Spoof Black";
      flex: 1 0 0;
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Headers/H2 */
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 80%; /* 32px */
      letter-spacing: -2px;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300a671'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .accordion-button:not(.collapsed)::before {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
  }
  .contactSection {
    // background-color: #FAF04B !important;
    padding: 20px 0;
    max-width: 1200px;
    margin: 0 auto 70px;
    background-color: $xlgrey;
    border-radius: 20px;
    position: relative;
    img.contactUs {
      max-width: 575px;
      position: absolute;
      bottom: 1;
      left: 10px;
    }
    img.floatElement {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
    }
    .contactForm {
      label {
        // font-weight: 500;
        margin: 10px 0;
      }
      input,
      textarea {
        border: none;
        border-radius: 8px;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .footerSection {
    background-color: $xlight;
    padding-top: 70px;
    p {
      max-width: 991px;
      margin: 15px auto;
    }
    text-align: center;
    .socialMedia {
      max-width: 300px;
      margin: 0 auto 70px;
      @include itemPosition(center, center);

      gap: 15px;
      .icon {
        @include roundShape(30px, 30px, $xlight);
        cursor: pointer;
        color: $primary;
        &:hover {
          @include roundShape(30px, 30px, $primary);
          color: $light;
        }
      }
    }
    .copyright {
      border-top: 1px solid $primary;
      p {
        a {
          font-weight: 700;
          color: $primary;
        }
      }
    }
  }

  //only for Color Changes in footer and dividing footer
}

// AuthPage
// .paddingTop{
//   padding: 60px 0 !important;
// }
.authPage {
  width: 100%;
  background: rgb(240, 250, 246);
  // background: linear-gradient(
  //   90deg,
  //   rgba(240, 250, 246, 1) 50%,
  //   rgba(255, 255, 255, 1) 50%
  // );
  background-color: #0c0d0a;

  // padding: 70px 0;
  .container {
    // background-color: white;
    border-radius: 15px;
    padding: 40px 0;
    box-shadow: none !important;

    .signUpImage {
      height: 50% !important;
    }
    .authImage {
      width: 100%;
      position: sticky;
      height: 100%;
      // height:100px;
      border-radius: 10px;
      top: 50px;
    }
    .content {
      max-width: 500px;
      margin: auto;
      .paddingAll {
        padding: 10px 50px;
      }
      .logo {
        padding: 10px;
        border-radius: 10px;
        padding-top: 20px;
        width: fit-content;
        display: block;
        margin: auto;
        box-shadow: 0px 14px 20px rgba(0, 166, 113, 0.19);
        border-radius: 10px;
        img {
          display: block;
          margin: auto;
          width: 63px;
          border-radius: 20px;
        }
      }
      a {
        text-decoration: none;
        font-weight: 600;
        color: #0c0d0a;
      }
      .form {
        padding: 15px;
        .radioBox {
          @include itemPosition(center, space-evenly);
        }
      }
      @font-face {
        font-family: "Aeonik";
        src: url("../public/__MACOSX/New\ Aeonik\ Trials/._AeonikTRIAL-Bold.otf")
          format("opentype");
      }
      label {
        color: black;
        font-family: "Aeonik", sans-serif;
      }
      .or {
        position: relative;
        margin-top: 25px;
        padding: 0 30px;

        p.orText {
          position: absolute;
          background-color: #f4f4f4;
          padding: 10px;
          left: 50%;
          transform: translateX(-50%);
          top: -22px;
        }
      }
      .socialMedia {
        @include itemPosition(center, center);
        gap: 40px;
        margin-top: 25px;
        @media screen and (max-width: 576px) {
          gap: 15px;
        }
        .box {
          @include itemPosition(center, center);
          padding: 5px;
          background-color: #ffffff;
          border-radius: 5px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.13);
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

// Header
.header {
  // background: $primary;
  background: #0c0d0a;
  // border-bottom: 1px solid #f4f4f4;
  padding: 8px 10px;
  padding-bottom: 40px;
  .container {
    nav.navbar {
      .logo {
        img {
          width: 120px;
        }
      }
      button.navbar-toggler {
        border-color: $light;
        &:focus {
          box-shadow: none;
        }
      }
      .profile {
        padding-left: 15px;
        @include itemPosition(center, flex-end);
        gap: 15px;
        .bell {
          position: relative;
          i.fa-bell {
            font-size: 24px;
            color: $light;
          }
          i.fa-circle {
            font-size: 8px;
            color: red;
            position: absolute;
            top: 5px;
            right: -2px;
          }
        }
        .profileImage {
          @include itemPosition(center, flex-start);
          gap: 15px;
          color: $light;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
      }
      ul.navbar-nav {
        justify-content: flex-end;
        flex: 1;
        border-right: 1px solid $light;
        padding-right: 10px;
        li.nav-item {
          a.nav-link {
            font-family: "Aeonik-Regular";
            color: var(--Brand-White, #f4f4f4);
            /* Buttons/Buttons */

            font-size: 14px;
            font-style: normal;
            font-weight: 400 !important;
            line-height: 24px;
            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

// Footer
section.footerSection {
  background-color: #0c0d0a;

  padding-top: 72px;
  @media screen and (max-width: 576px) {
    padding-top: 44px;
  }
  padding-bottom: 40px;
  p {
    max-width: 991px;
    margin: 15px auto;
    color: #ccc;
  }
  text-align: center;
  .socialMedia {
    max-width: 300px;
    margin: 0 auto 70px;
    @include itemPosition(center, center);
    gap: 15px;
    .icon {
      @include roundShape(30px, 30px, $xlight);
      cursor: pointer;
      color: $primary;
      &:hover {
        @include roundShape(30px, 30px, $primary);
        color: $light;
      }
    }
  }
  .copyright {
    border-top: 1px solid #f4f4f4;
    p {
      a {
        font-weight: 700;
        color: $primary;
      }
    }
  }
  .footerButton {
    background-color: transparent !important;
    border: none;
    height: 60px;
    font-family: "Aeonik-Regular";
    border: none !important;
    color: var(--Brand-White, #f4f4f4) !important;
    /* Buttons/Buttons */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */

    @media screen and (max-width: 576px) {
      height: 30px;
    }
    &:hover {
      color: #faf14b !important;
    }
  }
  .mainButtondiv :nth-child(2) {
    border-left: 1px solid #f5f5f5 !important;
    border-right: 1px solid #f5f5f5 !important;
    border-radius: 0px !important;
  }
  .mainButtondiv :nth-child(3) {
    border-left: 1px solid #f5f5f5 !important;
    border-right: 1px solid #f5f5f5 !important;
    border-radius: 0px !important;
  }
  .mainButtondiv {
    @media only screen and (max-width: 1200px) {
      justify-content: start;
      padding-left: 5px;
    }
  }
  .designText {
    padding-right: 30px;
    padding-top: 34px;
    padding-bottom: 34px;
    font-family: "Aeonik-Regular";
    @media only screen and (max-width: 1200px) {
      padding-left: 32px !important;
    }
    @media only screen and (max-width: 576px) {
      padding-left: 15px !important;
      padding-top: 8px !important;
      padding-bottom: 8px !important;
    }

    p {
      color: var(--Brand-White, #f4f4f4);
      text-align: end;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      @media only screen and (max-width: 1200px) {
        text-align: start;
      }
      &:hover {
        color: #faf14b !important;
      }
    }
  }
  .footerLogo {
    padding-right: 30px;
    display: flex;
    // justify-content: end;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    @media only screen and (max-width: 1200px) {
      justify-content: start;
      padding-left: 30px;
    }
    @media only screen and (max-width: 576px) {
      padding-left: 16px;
    }
    .icon i {
      width: 24px;

      font-size: 24px;
      &:hover {
        color: #faf14b !important;
        cursor: pointer;
      }
    }
  }
}

// Customer Header
.customerDashboard {
  section.main {
    // background-image: url("../public/assets/bannerBg.jpg");
    background-color: #faf14b;
    background-position: center;
    background-size: cover;
    .content {
      color: $light;
      text-align: center;
      padding: 50px 0;
      h3 {
        color: $light;
        font-weight: 400;
      }
      h1 {
        color: rgba($color: $light, $alpha: 0.7);
        span {
          color: $light;
        }
      }
    }
  }
  section.hospoStars {
    margin: 60px 0;
  }

  .newStars {
    margin: 0px !important;
    background-color: #0c0d0a;
    padding-top: 30px;
  }
}
.customerDashboard {
  div.main-11 {
    background-image: url("../public/assets/bannerBg.jpg");
    background-position: center;
    background-size: cover;
    .content {
      color: $light;
      text-align: center;
      padding: 50px 0;
      h3 {
        color: $light;
        font-weight: 400;
      }
      h1 {
        color: rgba($color: $light, $alpha: 0.7);
        span {
          color: $light;
        }
      }
    }
  }
  section.hospoStars {
    margin: 60px 0;
  }
  section .newStars {
    padding: 30px 0 !important;
  }
}
//flex
.dflex {
  display: flex;
}
//center content
.jcc {
  justify-content: center;
}
//border Radius
.borderRadius {
  border-radius: 15px;
}

// Search List Page
.searchList {
  .searchContent {
    position: relative;
    background-color: #faf14b;
    border-radius: 15px;
    margin-top: 35px;
    height: 250px;
    cursor: pointer;
    img {
      width: 100%;
      border-radius: 15px;
    }
    .text {
      padding: 15px;
      p {
        i {
          color: #0c0d0a;
        }
      }
    }
    .nextBtn {
      @include roundShape(40px, 40px, #0e0e0e);
      color: $light;
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }
    &:hover {
      box-shadow: 0px 4px 40px rgba(0, 166, 113, 0.21);
      .nextBtn {
        display: flex;
      }
    }
  }
}

// restaurent Profile
.restaurentProfile {
  .searchStaff {
    margin: 60px 0 20px 0;
    .searchBox {
      position: relative;
      input {
        background-color: $xlgrey;
        border: none;
        width: 100%;
        padding-left: 30px;
        &:focus {
          box-shadow: none;
        }
      }
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }
    }
  }
  .staffProfileList {
    border: 1px solid transparent;
    border-radius: 15px;
    background-color: $xlight;
    padding: 10px;
    margin: 10px 0;
    @include itemPosition(center, space-between);
    .image {
      @include roundShape(70px, 70px, transparent);
      padding: 5px;
      img {
        width: 100%;
        border-radius: 50%;
        height: 100%;
      }
    }
    .text {
      p {
        a {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}

.staffProfile {
  background-color: #0c0d0a;

  .profileData {
    // background-image: url("../public/assets/staffBackground.png");
    background-color: #faf14b;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    padding: 15px;
    margin: 30px 0 60px;
    .profileContent {
      @include itemPosition(center, flex-start);
      gap: 15px;
      // border-right: 1px solid $lgrey;
      position: relative;
      .image {
        @include roundShape(200px, 200px, transparent);
        padding: 10px;
        position: absolute;
        top: -10px;
        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
          // objf
          object-fit: cover;
        }
      }
      .text {
        margin-left: 220px;
        padding: 20px 0;
        font-family: "Aeonik-Regular";
        p {
          font-family: "Aeonik-Regular";
          color: #0c0d0a;
          i {
            color: #0c0d0a;
          }
          color: $grey;
        }
        h1 {
          font-family: "Aeonik-Regular" !important;
        }
        .review {
          @include itemPosition(center, flex-start);
          .star {
            i {
              color: $yellow;
            }
          }
          p {
            color: $dark;
            font-weight: 600;
          }
        }
      }
    }
    .prices {
      a {
        font-family: "Aeonik-Regular";
        text-decoration: none;
        color: #0e0e0e;
        font-weight: 500;
      }
    }
  }
  .feedback {
    @include itemPosition(center, space-between);
  }
  .feedbackContent {
    margin: 15px 0;
    &:not(:last-child) {
      border-bottom: 1px solid $grey;
    }
    .ratings {
      @include itemPosition(center, flex-start);
      gap: 15px;
      margin: 15px 0;
      p {
        margin-bottom: 0;
        font-weight: 600;
      }
      .star {
        i {
          color: $yellow;
        }
      }
      .time {
        p {
          font-weight: 400;
          color: $grey;
        }
      }
    }
    .text {
      p {
        color: $grey;
        margin-top: 10px;
      }
    }
  }
  .viewMore {
    a {
      text-decoration: none;
      color: $primary;
      font-weight: 600;
    }
  }
  .TippingBox {
    padding: 15px;
    background-color: $xlgrey;
    border-radius: 15px;
    margin: 0px 0;
  }
  .TippingPayment {
    padding: 15px;
    background-color: $xlgrey;
    border-radius: 15px;
    margin: 20px 0;
    .paymentCards {
      @include itemPosition(center, flex-start);
      gap: 15px;
    }
    input {
      font-weight: 600;
    }
  }
  .profileOptions {
    label {
      margin-bottom: 10px;
      color: $grey;
      font-size: 14px;
    }
    select {
      background-color: $xlgrey;
      &:focus {
        box-shadow: none;
        border: 1px solid $primary;
      }
    }
    padding: 15px;
    background-color: $xlgrey;
    border-radius: 15px;
    margin-bottom: 40px;
  }
}

// tipping history
.tippingHistroy {
  .postContainer {
    @media screen and (max-width: 576px) {
      padding-left: 0px;
      padding-right: 0px;
    }
    padding-left: 100px;
    padding-right: 100px;
  }
  .topBar {
    background-color: #f4f4f4;
    h3 {
      color: #0c0d0a !important;
      font-family: "Aeonik-Regular";
    }
    p {
      color: #0c0d0a !important;
      font-family: "Aeonik-Regular";
    }
    .content {
      @include itemPosition(center, flex-start);
      .heading {
        border-right: 1px solid $grey;
        padding: 10px 15px;
        font-family: "Aeonik-Bold";
        h3 {
          font-family: "Aeonik-Bold";
        }
      }
      .price {
        padding: 10px 15px;
        font-family: "Aeonik-Regular";
      }
    }
  }
  .tabs {
    @include itemPosition(center, flex-start);

    border-bottom: 2px solid $grey;
    padding: 20px 0;
    .tab {
      width: 200px;
      position: relative;
      text-align: center;
      // color: $grey;
      color: #f4f4f4;
      height: 30px;
      font-size: 15px;
      font-family: "Aeonik-Regular";
      cursor: pointer;
      .line {
        height: 6px;
        background-color: transparent;
        position: absolute;
        bottom: -24px;
        width: 100%;
        border-radius: 5px;
      }

      &.active {
        .line {
          // background-color: $primary;
          background-color: #faf14b;
        }
      }
    }
  }
  .tabContent {
    padding: 30px 0;
    .content {
      @include itemPosition(center, space-between);
      flex-wrap: wrap;
      .dates {
        @include itemPosition(center, flex-start);
        gap: 12px;
        flex-wrap: wrap;
        .datePicker {
          label {
            color: #f4f4f4 !important;
          }
          color: #f4f4f4;
          @include itemPosition(center, flex-start);
          gap: 15px;
          input {
            border: 1px solid black;
            &:focus {
              box-shadow: none;
            }
          }
          @media screen and (max-width: 576px) {
            #pickTo {
              margin-left: 20px;
            }
          }
        }
        .dropdown {
          select {
            border: 1px solid $primary;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
      .price {
        font-family: "Aeonik-Regular";
        h3 {
          font-family: "Aeonik-Regular";
        }
      }
    }
    .searchStaff {
      margin: 20px 0;
      .searchBox {
        position: relative;
        input {
          background-color: $xlgrey;
          border: none;
          width: 100%;
          padding-left: 40px;
          &:focus {
            box-shadow: none;
          }
        }
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }
      }
    }
    .staffProfileList {
      color: #f4f4f4;
      border: 1px solid #f5f5f5;
      border-radius: 15px;
      background-color: transparent;
      padding: 10px;
      margin: 10px 0;
      @include itemPosition(center, space-between);
      gap: 5px;
      .staffProfile {
        @include itemPosition(center, flex-start);
        gap: 10px;
        .image {
          @include roundShape(70px, 70px, transparent);
          padding: 5px;
          img {
            width: 100%;
            border-radius: 50%;
            height: 100%;
          }
        }
      }

      .text {
        p {
          a {
            text-decoration: none;
            color: #faf14b;
            display: none;
          }
        }
      }
      .text2 {
        flex: 1;
        font-family: "Aeonik-Regular";
        @include itemPosition(center, flex-start);
        margin-left: 10px;
        p.date {
          color: #f4f4f4;
          padding-left: 10px;
          margin-left: 10px;
          border-left: 1px solid $grey;
        }
      }
      &:hover {
        background-color: transparent;
        .text {
          p {
            a {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

// profile Setting
.ProfileSetting {
  background-color: #0c0d0a;
  .topBar {
    background-color: #f4f4f4;
    padding: 10px 0;
  }
  .profileSettingConent {
    // background-color: rgba($color: $lgrey, $alpha: 0.2);
    background-color: #f4f4f4;
    border-radius: 15px;
    margin: 30px 0;
    padding: 15px;
    .profile {
      width: 100px;
      height: 100px;
      position: relative;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .icon {
        position: absolute;
        bottom: -6px;
        right: 0px;
        border: 1px solid $primary;
        @include roundShape(35px, 35px, $light);
      }
    }
    .inputField {
      label {
        i {
          color: #0c0d0a;
        }
      }
    }
    .payment {
      @include itemPosition(center, space-between);
      flex-wrap: wrap;
      gap: 20px;
      max-width: 700px;
      margin-top: 20px;
      .imageCard {
        img {
          border-radius: 5px;
        }
        @include itemPosition(center, flex-start);
        gap: 10px;
      }
    }

    a {
      text-decoration: none;
      color: $primary;
      font-weight: 500;
      margin-top: 20px;
      display: block;
    }
  }
}

// Customer Support
.customerSupport {
  .topBar {
    background-color: #f4f4f4;
    padding: 10px 0;
    .content {
      @include itemPosition(center, space-between);
      a {
        text-decoration: none;
        color: $primary;
        font-weight: 500;
      }
    }
  }
  .formBox {
    background-color: rgba($color: $xlgrey, $alpha: 1);
    padding: 15px;
    border-radius: 15px;
    margin: 60px 0;
  }
}

// My queries
.myQueries {
  .tabs {
    @include itemPosition(center, flex-start);
    border-bottom: 2px solid $grey;
    padding: 20px 0;
    .tab {
      width: 200px;
      position: relative;
      text-align: center;
      color: $grey;
      cursor: pointer;
      .line {
        height: 6px;
        background-color: transparent;
        position: absolute;
        bottom: -24px;
        width: 100%;
        border-radius: 5px;
      }

      &.active {
        .line {
          background-color: $primary;
        }
      }
    }
  }
  .tabContent {
    padding: 30px 0;
    .queryList {
      background-color: $xlight;
      border: 1px solid $lgrey;
      padding: 12px;
      border-radius: 15px;
      margin: 12px 0;
      color: $grey;
      p.content {
        border-right: 1px solid $primary;
      }
      a {
        text-decoration: none;
        color: $primary;
      }
    }
  }
  .query {
    background-color: rgba($color: $lgrey, $alpha: 0.1);
    padding: 12px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .queryreplay {
    .content {
      border: 1px solid $lgrey;
      padding: 15px;
      border-radius: 15px;
    }
  }
}

// Notification
.notification {
  .topBar {
    background-color: #f4f4f4;
    padding: 15px 0;
  }
  .notifications {
    @include itemPosition(center, space-between);
    background-color: $xlgrey;
    border-radius: 15px;
    padding: 15px;
    margin: 15px 0;
    &.recentNotification {
      background-color: rgba(255, 0, 0, 0.07);
    }
  }
}

.RIAddAddress {
  section.main {
    // background-image: url("../public/assets/addAddress.png");
    background-color: #faf14b;
    background-position: bottom;
    background-size: cover;
    .content {
      color: $light;
      text-align: center;
      padding: 50px 0;
      h3 {
        color: $light;
        font-weight: 400;
      }
      h1 {
        color: rgba($color: $light, $alpha: 0.7);
        span {
          color: $light;
        }
      }
    }
  }
  section.form {
    .container {
      // background-color: rgba($color: $lgrey, $alpha: 0.1);
      background-color: #f4f4f4;
      border-radius: 15px;
      padding-bottom: 10px;
      margin-bottom: 15px;
      .content {
        @include itemPosition(center, flex-start);
        gap: 20px;
        padding: 15px 0;
        .UploadBox {
          // width: 200px;
          height: 100;
          border: 2px dashed #0c0d0a;
          padding: 20px;
          border-radius: 15px;
          @include itemPosition(center, center);
          flex-direction: column;
          text-align: center;
          background-color: $xlight;
          i {
            color: #0c0d0a;
          }
        }
        .uploadedImage {
          width: 150px;
          position: relative;
          img {
            width: 100%;
            border-radius: 15px;
          }
          i {
            position: absolute;
            top: -10px;
            right: -10px;
            color: red;
            border: 1px solid red;
            @include roundShape(25px, 25px, $light);
          }
        }
      }
      .inputField {
        margin: 8px 0;
        .inputBox {
          input {
            background-color: transparent;
          }
        }
      }
    }
  }
  .EditDetails {
    color: $primary;
    a {
      text-decoration: none;
      color: #f4f4f4;
    }
  }
  .recordData {
    .content {
      padding: 15px;
      border: 1px solid $light;
      border-radius: 15px;
      box-shadow: 0px 4px 20px rgba(0, 166, 113, 0.12);
      margin: 7.5px;
      &:hover {
        border: 1px solid #faf14b;
      }
      .icon {
        @include roundShape(45px, 45px, $xlight);
        i {
          color: $primary;
        }
      }
      p {
        color: $grey;
        margin-bottom: 0;
      }
    }
  }
  .statusDetails {
    background-color: $xlgrey;
    padding: 15px;
    border-radius: 15px;
    font-family: "Aeonik-Regular";
    .fw-regular {
      font-weight: 500;
    }
    .previusJob {
      @include itemPosition(center, flex-end);
      .link {
        a {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}
// starts here 17-05-2023

.mainContainer {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.staffDashboard {
  background-color: #0c0d0a;
  color: #f5f5f5;
}
@font-face {
  font-family: "Aeonik-Bold";
  src: url("../src/assets/fonts/Aeonik/Aeonik-Bold-Web/Aeonik-Bold.woff");
  src: url("../src/assets/fonts/Aeonik/Aeonik-Bold-Web/Aeonik-Bold.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Aeonik-Regular";
  src: url("../src/assets/fonts/AeonikRegular/Aeonik-Regular-Web/Aeonik-Regular.woff");
  src: url("../src/assets/fonts/AeonikRegular/Aeonik-Regular-Web/Aeonik-Regular.woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Spoof Black";
  src: url("../src/assets/fonts/Spoof/SpoofBlackWeb/WOFF/Spoof-Black.woff");
  src: url("../src/assets/fonts/Spoof/SpoofBlackWeb/WOFF2/Spoof-Black.woff2");
  font-weight: 300;
  font-style: normal;
}

.pubstarNoti {
  @media screen and (max-width: 576px) {
    padding: 10px;
  }
  display: flex;
  padding: 32px;
  padding-bottom: 10px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  .notification {
    color: var(--Brand-White, #f4f4f4);
    /* Desktop/Headers/H1 */
    font-family: "Spoof Black";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 60px; /* 93.75% */
    letter-spacing: -3.2px;
    text-transform: uppercase;
    @media only screen and (max-width: 576px) {
      color: var(--Brand-White, #f4f4f4);
      // font-family: Spoof Trial;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 89%; /* 28.48px */
      letter-spacing: -1.6px;
    }
  }
  .tipsMessage {
    color: var(--Brand-White, #f4f4f4);
    font-size: 20px;
    font-style: normal;
    font-weight: 250;
    line-height: normal;
    .newTipmessage {
      color: var(--Brand-White, #f4f4f4);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  svg {
    // fill: var(--Brand-black, #0E0E0E);
    width: 115.273px;
    height: 115.273px;
    transform: rotate(9.553deg);
    flex-shrink: 0;
  }
}
.balanceDiv {
  @media only screen and (max-width: 1200px) {
    display: contents !important;
  }
  padding-left: 20px;
  display: grid;
  gap: 32px;
  // padding-right: 32px;
  .balance {
    @media only screen and (max-width: 1200px) {
      margin: 10px;
    }
    @media only screen and (max-width: 576px) {
      margin: 0px;
    }
    border-radius: 20px;
    background-color: #faf14b;
    padding: 32px;
    .balanceText {
      display: flex;
      height: 64px;
      padding-bottom: 0px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 2px solid var(--Brand-black, #0e0e0e);
      @media screen and (max-width: 576px) {
        height: 0px;
        padding-bottom: 24px;
        padding-top: 8px;
      }
      .text {
        color: var(--Brand-black, #0e0e0e);
        /* Desktop/Headers/H2 */
        font-family: "Spoof Black";
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 80%; /* 32px */
        letter-spacing: -2px;
        flex: 1 0 0;
        @media screen and (max-width: 576px) {
          color: var(--Brand-black, #0e0e0e);
          /* Mobile/HEADERS/H1 */
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 90%; /* 21.6px */
          letter-spacing: -1.2px;
          text-transform: uppercase;
        }
      }
    }

    .balanceText1 {
      border-bottom: 2px solid var(--Brand-black, #0e0e0e);
      padding-bottom: 24px;
      align-items: center;
      display: flex;
      @media screen and (max-width: 576px) {
        padding-bottom: 24px;
        padding-top: 8px;
      }
      .text {
        color: var(--Brand-black, #0e0e0e);
        /* Desktop/Headers/H2 */
        font-family: "Spoof Black";
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 80%; /* 32px */
        letter-spacing: -2px;
        flex: 1 0 0;
        @media screen and (max-width: 576px) {
          color: var(--Brand-black, #0e0e0e);
          /* Mobile/HEADERS/H1 */
          font-size: 24px;
          font-style: normal;
          font-weight: 800;
          line-height: 90%; /* 21.6px */
          letter-spacing: -1.2px;
          text-transform: uppercase;
        }
      }
    }
    .balanceHistory {
      .payment {
        color: var(--Brand-black, #0e0e0e);
        /* Desktop/Callout 1 */
        font-family: "Aeonik-Bold";
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
        @media screen and (max-width: 576px) {
          color: var(--Brand-black, #0e0e0e);
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 89%; /* 21.36px */
        }
      }
      .GDP {
        margin-top: 10px;
        color: var(--Brand-black, #0e0e0e);
        font-feature-settings: "clig" off, "liga" off;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .legend {
        border-radius: 100px;
        border: 0.5px solid var(--Brand-black, #0e0e0e);
        width: 10px;
        height: 10px;
      }
      .mainlegender {
        display: flex;
        align-items: center;
        gap: 15px;

        .lagenderText {
          color: var(--Brand-black, #0e0e0e);
          font-feature-settings: "clig" off, "liga" off;

          /* Desktop/Caption */
          // font-family: Aeonik;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 125% */
          opacity: 0.5;
        }
      }
    }

    .withdrawButton {
      // width: 50%;
      background-color: #0c0d0a !important;
      color: #f5f5f5 !important;

      .withdrawButtonText {
        /* Buttons/Buttons */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0px;
      }

      &:hover {
        background-color: transparent !important;
        border: 2px solid #0e0e0e !important;
        color: #0c0d0a !important;
      }
    }
  }
  .emplyeeImages {
    padding-top: 20px !important;
    padding-bottom: 5px;
  }
}
.dollorIcon {
  @media only screen and (max-width: 1200px) {
    display: none;
  }
}
.tips {
  padding-top: 32px;
  @media screen and (max-width: 576px) {
    padding-top: 20px;
  }
  // padding-bottom: 32px;
  margin-left: 0px !important;
  margin-right: 0px !important;

  .yourTips {
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: #f5f5f5;
    border-radius: 20px;
    ::-webkit-scrollbar-thumb {
      background: #0e0e0e !important;
    }

    ::-webkit-scrollbar-track {
      background: var(--Divide-stroke, rgba(0, 0, 0, 0.2)) !important;
    }
    .sortTip {
      @media only screen and (max-width: 1200px) {
        display: contents;
      }
    }
    .yourtipsText {
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Headers/H2 */
      font-family: "Spoof Black";

      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 80%; /* 32px */
      letter-spacing: -2px;
      @media screen and (max-width: 576px) {
        color: var(--Brand-black, #0e0e0e);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 90%; /* 21.6px */
        letter-spacing: -1.2px;
        text-transform: uppercase;
      }
    }
  }

  .tipsRecieved {
    padding: 24px 0px;
    @media only screen and (max-width: 576px) {
      .tipRecievedDollor {
        padding: 8px;
        .tipsRecievedAmountdiv {
          padding: 0px 0px !important;
          .totalRecievedAmount {
            // font-family: Aeonik;
            margin-bottom: 0px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
      .tipsRecievedAmount {
        color: var(--Brand-black, #0e0e0e);
        font-feature-settings: "clig" off, "liga" off;
        /* Mobile/Caption */
        font-family: Aeonik;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 74.667px;
      }
      .totalAmount {
        width: 41.667px !important;
      }
      .totalTipsAmount {
        width: 74.667px !important;
      }
    }

    .tipsRecievedAmount {
      margin-bottom: 0px;
      color: var(--Brand-black, #0e0e0e);
      font-feature-settings: "clig" off, "liga" off;
      /* Desktop/Caption */
      font-family: "Aeonik-Regular";
      opacity: 0.5;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .totalRecievedAmount {
      color: var(--Brand-black, #0e0e0e);
      font-feature-settings: "clig" off, "liga" off;
      font-family: "Aeonik-Bold";
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }
  }
  .profileView {
    @media only screen and (max-width: 1200px) {
      display: flex;
    }
  }
  @media only screen and (max-width: 1200px) {
    .displayNone {
      display: none !important;
    }
  }

  .profile {
    img {
      display: flex;
      width: 64px;
      height: 64px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      border-radius: 50%;
      @media screen and (max-width: 576px) {
        width: 45px !important;
        height: 45px !important;
      }
    }
  }
  .customerName {
    color: var(--Brand-black, #0e0e0e);
    // /* Desktop/Tags */
    font-family: "Aeonik-Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .customerfeedback {
    align-self: stretch;
    color: var(--Brand-black, #0e0e0e);
    /* Desktop/Body/B3 */
    font-family: "Aeonik-Regular";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 27px */
  }
  .tipdate {
    color: var(--Brand-black, #0e0e0e);
    font-feature-settings: "clig" off, "liga" off;
    /* Desktop/Caption */
    font-family: "Aeonik-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    opacity: 0.5;
  }
  .totalAmontpay {
    color: var(--Brand-black, #0e0e0e);
    text-align: right;
    font-feature-settings: "clig" off, "liga" off;
    /* Desktop/Caption */
    font-family: "Aeonik-Regular";
    background: #faf14b;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
  }
  .graphDiv {
    background-color: #f5f5f5;
    @media screen and (max-width: 576px) {
      margin-top: 20px;
    }
    .reapeatTipper {
      padding-top: 20px;
      color: var(--Brand-black, #0e0e0e);
      /* Desktop/Tags */
      font-family: "Aeonik-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

// ends here 17-05-2023

.StaffHomeScreen {
  section.main {
    background-image: url("../public/assets/staffInterface.png");
    background-position: center;
    background-size: cover;
    .content {
      color: $light;
      text-align: center;
      padding: 50px 0;
      h3 {
        color: $light;
        font-weight: 400;
      }
      h1 {
        color: rgba($color: $light, $alpha: 0.7);
        span {
          color: $light;
        }
      }
    }
  }
  section.hospoStars {
    margin: 60px 0;
  }

  section.rearchResults {
    p.noResults {
      max-width: 550px;
      margin: auto;
      color: $grey;
    }
    .pubDetails {
      @include itemPosition(center, flex-start);
      padding: 15px;
      margin: 15px 0;
      border-radius: 15px;
      gap: 15px;
      position: relative;
      .image {
        width: 125px;
        height: 125px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          border-radius: 10px;
          height: 100%;
          object-fit: cover;
        }
      }
      .text {
        p {
          i {
            color: $primary;
          }
          span {
            font-weight: 600;
          }
        }
      }
      .next {
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        @include roundShape(40px, 40px, #faf14b);
        display: none;
        i {
          color: #0e0e0e;
        }
      }
      &:hover {
        box-shadow: 0px 4px 40px rgba(0, 166, 113, 0.21);
        .next {
          display: flex;
        }
      }
    }
  }
}

// tipping history
.invitationRequest {
  .topBar {
    background-color: #f4f4f4;
    .content {
      @include itemPosition(center, flex-start);
      .heading {
        padding: 15px;
        h3 {
          font-family: "Aeonik-Bold";
        }
      }
    }
  }
  .tabs {
    @include itemPosition(center, flex-start);
    border-bottom: 2px solid $grey;
    padding: 20px 0;

    .tab {
      width: 200px;
      font-family: "Aeonik-Regular";
      position: relative;
      text-align: center;
      color: #f4f4f4;
      cursor: pointer;
      .line {
        height: 6px;
        background-color: transparent;
        position: absolute;
        bottom: -24px;
        width: 100%;
        border-radius: 5px;
      }

      &.active {
        .line {
          background-color: #faf14b;
        }
      }
    }
  }
  .tabContent {
    padding: 30px 0;
    .content {
      @include itemPosition(center, space-between);
      flex-wrap: wrap;
      .dates {
        @include itemPosition(center, flex-start);
        gap: 12px;
        flex-wrap: wrap;
        .datePicker {
          label {
            color: #f4f4f4 !important;
          }
          color: #f4f4f4;
          @include itemPosition(center, flex-start);
          gap: 15px;
          input {
            border: 1px solid black;
            &:focus {
              box-shadow: none;
            }
          }
        }
        .dropdown {
          select {
            border: 1px solid $primary;
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
    .searchStaff {
      margin: 20px 0;
      .searchBox {
        position: relative;
        input {
          background-color: $xlgrey;
          border: none;
          width: 100%;
          padding-left: 40px;
          &:focus {
            box-shadow: none;
          }
        }
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }
      }
    }
    .staffProfileList {
      border: 1px solid $primary;
      border-radius: 15px;
      background-color: $light;
      padding: 10px;
      margin: 10px 0;
      @include itemPosition(center, space-between);
      .firstdata {
        @include itemPosition(center, flex-start);
        gap: 15px;
        .image {
          @include roundShape(70px, 70px, transparent);
          padding: 5px;
          img {
            width: 100%;
            border-radius: 50%;
            height: 100%;
          }
        }
        .text {
          border-right: 1px solid #faf14b;

          p {
            font-family: "Aeonik-Regular";
            max-width: 250px;
            font-size: 14px;
            color: $grey span {
              color: $dark;
            }
          }
        }
        .date {
          p {
            font-family: "Aeonik-Regular";
          }
        }
      }
      .secondData {
        @include itemPosition(center, flex-end);
        gap: 50px;
        .link {
          a {
            font-family: "Aeonik-Regular";
            text-decoration: none;
            color: $primary;
          }
        }
      }
      &:hover {
        background-color: $xlight;
        .text {
          p {
            a {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

.staffProfileList {
  border: 1px solid $primary;
  border-radius: 15px;
  background-color: $light;
  padding: 10px;
  margin: 10px 0;
  @include itemPosition(center, space-between);
  .firstdata {
    @include itemPosition(center, flex-start);
    gap: 15px;
    .image {
      @include roundShape(70px, 70px, #faf14b);
      padding: 5px;
      img {
        width: 100%;
        border-radius: 50%;
        height: 100%;
      }
    }
    .text {
      border-right: 1px solid #faf14b;
      font-family: "Aeonik-Regular";
      p {
        padding-right: 10px;
        max-width: 250px;
        font-size: 14px;
        color: $grey span {
          color: $dark;
        }
      }
    }
    .date {
      font-family: "Aeonik-Regular";
    }
  }
  .secondData {
    @include itemPosition(center, flex-end);
    gap: 50px;
    .link {
      a {
        text-decoration: none;
        color: $primary;
      }
    }
  }
  &:hover {
    background-color: $xlight;
    .text {
      p {
        a {
          display: inline-block;
        }
      }
    }
  }
}

// tipping history
.ManageStaff {
  .topBar {
    background-color: #f4f4f4;
    .content {
      @include itemPosition(center, flex-start);
      .heading {
        padding: 15px;
        h3 {
          font-family: "Aeonik-Bold";
        }
      }
    }
  }
  .searchStaff {
    margin: 20px 0;
    .searchBox {
      @include itemPosition(center, flex-start);
      max-width: 700px;
      gap: 15px;
      .box {
        position: relative;
        flex: 1;
        input {
          background-color: $xlgrey;
          border: none;
          width: 100%;
          padding-left: 40px;
          height: 50px;
          &:focus {
            box-shadow: none;
          }
        }
        i.search {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 16px;
        }
      }
      .text {
        p {
          color: $primary;
        }
      }
    }
  }
  .staffList {
    // @include itemPosition(center, auto);
    margin: 7.5px 0;
    gap: 15px;
    .staffProfileList {
      font-family: "Aeonik-Regular";
      flex: 1;
      border: 1px solid $primary;
      border-radius: 15px;
      background-color: #f5f5f5;
      padding: 10px;
      margin: 10px 0;
      @include itemPosition(center, space-between);
      flex-wrap: wrap;
      .image {
        @include roundShape(70px, 70px, transparent);
        padding: 5px;
        img {
          width: 100%;
          border-radius: 50%;
          height: 100%;
        }
      }
      .text {
        p {
          a {
            text-decoration: none;
            color: $primary;
          }
        }
      }
    }
  }
  .icon {
    @include roundShape(67px, 67px, $xlight);
    cursor: pointer;
    i {
      color: $primary;
    }
  }
}

.rolesandPermission {
  .topBar {
    background-color: #f4f4f4;
    padding: 15px 0;
  }
  .newRole {
    @include itemPosition(center, space-between);
  }

  .roleItam {
    @include itemPosition(flex-start, space-between);
    gap: 10px;
    margin: 15px 0;
    .accordion {
      flex: 1;
      button {
        color: $dark;
        &:focus {
          box-shadow: none;
        }
      }
      .accordion-button:not(.collapsed) {
        background-color: $light;
      }
      .roles {
        @include itemPosition(center, space-between);
        width: 100%;
        i {
          color: $primary;
          cursor: pointer;
        }
        a {
          text-decoration: none;
          color: $primary;
          margin-right: 15px;
        }
      }
    }
    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300a671'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2300a671'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .icon {
      @include roundShape(40px, 40px, $xlight);
      cursor: pointer;
      i {
        color: $primary;
      }
    }
    .accordion-body {
      background-color: $xlgrey;
    }
  }
}

.TipManagement2 {
  .requestToTip {
    background-color: $xlgrey;
    padding: 12px;
    border-radius: 15px;
  }
  .data {
    color: $grey;
    .star {
      i {
        color: $yellow;
      }
    }
    input {
      &:focus {
        box-shadow: none;
        border: 1px solid black;
      }
    }
  }
  .text {
    color: #00a671;
    font-weight: 500;
    i {
      color: $primary;
    }
  }

  .listdata {
    @include itemPosition(center, space-between);
    padding: 10px;
    border-radius: 15px;
    border: 1px solid $lgrey;
    margin: 15px 0;
    .profile {
      @include itemPosition(center, flex-start);
      gap: 15px;

      .image {
        @include roundShape(70px, 70px, $primary);
        img {
          @include roundShape(65px, 65px, none);
        }
      }
      .text {
        color: $dark;
        h4 {
          font-size: 20px;
        }
        p {
          color: $grey;
        }
      }
    }
  }
}

.Reports {
  .topBar {
    background-color: #f4f4f4;
    padding: 15px 0;
  }
  .content {
    @include itemPosition(center, space-between);
    flex-wrap: wrap;
    .dates {
      @include itemPosition(center, flex-start);
      gap: 12px;
      flex-wrap: wrap;
      .datePicker {
        label {
          color: #f4f4f4;
        }
        color: #f4f4f4;
        @include itemPosition(center, flex-start);
        gap: 15px;
        input {
          border: 1px solid black;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .dropdown {
        select {
          border: 1px solid $primary;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
  h5 {
    color: $primary;
  }
  select {
    font-family: "Aeonik-Regular";
    &:focus {
      box-shadow: none;
      border: 1px solid $primary;
    }
  }
  .card {
    border-radius: 15px 15px 0 0;
    margin-bottom: 20px;
    .table {
      background-color: $xlgrey;
      margin-bottom: 0;
      thead {
        tr {
          th {
            background-color: $primary;
            color: $light;
            &:first-child {
              border-radius: 15px 0 0 0;
            }
            &:last-child {
              border-radius: 0 15px 0 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .ProfileDesign {
    img.mainImage {
      position: static;
      bottom: auto;
      max-width: 350px;
      object-fit: cover;
    }
  }
}

@media only screen and (max-width: 991px) {
  // Header
  .header {
    background: #0c0d0a;
    padding: 0px;
    .container {
      padding-top: 10px;
      nav.navbar {
        .logo {
          img {
            width: 60px;
            height: fit-content;
          }
        }
        .profile {
          @include itemPosition(center, center);
          padding: 0;
        }
        ul.navbar-nav {
          border-right: 0px solid $light;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  div#navbarSupportedContent ul {
    display: block;
  }
  .profile {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 923px) {
  .homePage {
    // .howitworksSection {
    //   .howitworksOptions {
    //     flex-wrap: wrap;
    //     .option1 {
    //       border-radius: 15px 0 0 15px;
    //     }
    //     .option2 {
    //       border-radius: 0 15px 15px 0;
    //     }
    //     .option3 {
    //       border-radius: 0 0 15px 15px;
    //     }
    //   }
    //   .howitworkscontent {
    //     img {
    //       width: 100%;
    //     }
    //   }
    // }
    .faqSection {
      img {
        width: 100%;
      }
    }
    .contactSection {
      img.contactUs {
        max-width: 350px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .homePage {
    // .howitworksSection {
    //   .howitworksOptions {
    //     flex-wrap: wrap;
    //     .option1 {
    //       border-radius: 15px 0 0 15px;
    //     }
    //     .option2 {
    //       border-radius: 0 15px 15px 0;
    //     }
    //     .option3 {
    //       border-radius: 0 0 15px 15px;
    //     }
    //   }
    //   .howitworkscontent {
    //     h1 {
    //       text-align: center;
    //       margin-top: 40px;
    //     }
    //     img {
    //       width: 75%;
    //     }
    //     .textBox {
    //       max-width: 450px;
    //       margin: auto;
    //     }
    //   }
    // }
    .faqSection {
      img {
        width: 60%;
        margin-bottom: 50px;
      }
    }
    .contactSection {
      img.contactUs {
        max-width: 350px;
      }
      img.mobileImage {
        width: 60%;
        margin: auto;
      }
    }
  }

  .authPage {
    background: #0c0d0a;
    padding: 10px 10px;
    .container {
      padding: 160px 0;

      .authImage {
        display: none;
      }
      .content {
        .form {
          padding: 0px;
        }
        .socialMedia {
          .box {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }

  .header {
    .HeaderContent {
      .logo {
        img {
          width: 50px;
        }
      }
      .headerLinks {
        flex-direction: row-reverse;
        .btn-main {
          padding: 0;
        }
        .links {
          gap: 10px;
          border-right: none;
          border-left: 1px solid $light;
        }
        .profile {
          padding-right: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .faqSection {
    img {
      margin-bottom: 0px !important;
    }
  }
}
@media only screen and (max-width: 623px) {
  h1 {
    font-size: 25px;
  }
  // .homePage {
  //   .headerSection {
  //     .headerContent {
  //       .content {
  //         p {
  //           font-size: 16px;
  //         }
  //       }
  //     }
  //   }
  //   .howitworksSection {
  //     .howitworksOptions {
  //       flex-wrap: wrap;
  //       .option1 {
  //         border-radius: 15px 15px 0 0;
  //       }
  //       .option2 {
  //         border-radius: 0;
  //       }
  //       .option3 {
  //         border-radius: 0 0 15px 15px;
  //       }
  //     }
  //   }
  // }
  .staffProfile {
    .profileData {
      padding: 5px;
      .profileContent {
        border-right: none;
        .image {
          @include roundShape(120px, 120px, transparent);
          padding: 5px;
          position: absolute;
          top: 25px;
          left: 0px;
        }
        .text {
          margin-left: 130px;
          padding: 20px 0;
        }
      }
    }
    .feedback {
      h2 {
        font-size: 18px;
      }
    }
  }
  .tippingHistroy {
    .tabs {
      padding: 20px 0;
      .tab {
        height: 30px;
        font-size: 14px;
      }
    }
  }

  .inputField-inline {
    @include itemPosition(flex-start, flex-start);
    flex-direction: column;
  }
}

.staffBox {
  .selectItems {
    max-width: 350px;
    margin: auto;
    position: relative;
    .selectBox {
      @include itemPosition(center, space-between);
      border: 1px solid $grey;
      border-radius: 8px;
      margin: 10px 0px;
      padding: 10px;
    }
    .selectContent {
      position: absolute;
      left: 0;
      top: 88px;
      border-radius: 10px;
      border: 1px solid $grey;
      background: $light;
      width: 100%;
      padding: 10px 0px;
      .listBox {
        max-height: 250px;
        overflow: auto;
        width: 100%;
        padding: 8px;
        .contentList {
          @include itemPosition(flex-start, space-between);
          padding: 10px 0;
          border-bottom: 1px solid $grey;
          &:last-child {
            border-bottom: none;
          }
          .input {
            max-width: 80px;
            input {
              border: 1px solid black;
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.findLocal {
  .inputField {
    max-width: 991px;

    margin: auto;

    position: relative;

    input {
      border: 1px solid $primary;

      padding: 5px 35px;

      &:focus {
        box-shadow: none;
      }
    }

    i.search {
      position: absolute;

      left: 10px;

      top: 50%;

      transform: translateY(-50%);
    }

    i.close {
      position: absolute;

      right: 10px;

      top: 50%;

      transform: translateY(-50%);
    }
  }
}

// .notification-item {
//   margin-bottom: 10px;
// }

// .notification-item:hover {
//   background-color: #ffe6e6;
// }

// .notification-item::after {
//   content: "";
//   display: block;
//   height: 1px;
//   background-color: #333;
//   margin-top: 10px;
// }

//For Link tag of React Router Dom
.custom-link {
  text-decoration: none;
  color: inherit;
  &:hover {
    color: #faf14b;
  }
}

.notification-dropdown {
  background-color: transparent;
  border: none;
}

// .notification-badge {
//   background-color: red;
//   color: white;
//   border-radius: 50%;
//   width: 8px;
//   height: 8px;
//   position: absolute;
//   top: 0;
//   right: 0;
// }

.notification-menu {
  width: 300px;
}

.notification-header {
  font-size: 18px;
  font-weight: bold;
  padding: 8px;
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
}

.notification-item {
  padding: 8px 0;
}

.notification-date {
  color: #f4f4f4;
  font-size: 12px;
  padding-right: 16px;
}

.mark-as-read-button {
  background-color: transparent;
  border: none;
  color: blue;
  cursor: pointer;
}

.view-all-link {
  display: block;
  margin-top: 10px;
}

.no-notification-item {
  color: gray;
}

.notification-divider {
  margin: 8px 0;
  border: none;
  border-top: 1px solid #ccc;
}

.bell {
  position: relative;
  display: inline-block;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.mark-as-read-button-container {
  display: flex;
  justify-content: space-between;
  // margin-top: 10px;
}

// .toolsContainer2 {
//   position: relative;
//   .searchBox2 {
//     position: absolute;
//     top: 20px;
//     right: 136px;
//     width: 37%;
//     input {
//       padding-left: 30px;
//       border-radius: 12px;
//       height: 50px;
//       &:focus {
//         box-shadow: none;
//       }
//     }
//     i {
//       position: absolute;
//       left: 10px;
//       top: 50%;
//       transform: translateY(-50%);
//       color: $dark;
//     }
//     .serchResults {
//       width: 100%;
//       height: auto;
//       background-color: $light;
//       margin-top: 10px;
//       border-radius: 15px;
//       position: absolute;
//       top: 50px;
//       overflow: auto;
//       z-index: 4;
//       // display: none;

//       .result {
//         @include itemPosition(center, flex-start);
//         gap: 10px;
//         padding: 10px;
//         cursor: pointer;
//         &:not(:last-child) {
//           border-bottom: 0.5px solid $grey;
//         }
//         .image {
//           width: 50px;
//           height: 50px;
//           border-radius: 5px;
//           img {
//             width: 100%;
//             border-radius: 5px;
//           }
//         }
//         .roundImage {
//           @include roundShape(55px, 55px, $primary);
//           // padding: 10px;
//           img {
//             width: 100%;
//             @include roundShape(50px, 50px, $primary);
//           }
//         }
//         .text {
//           p {
//             color: $dark;
//           }
//         }
//       }
//     }
//   }
//   .filters {
//     .card {
//       position: relative;
//       border-radius: 12px;
//       width: 100%;
//       i {
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 10px;
//         color: $primary;
//       }
//       .p-multiselect {
//         padding-left: 20px;
//         border: none;
//         color: $primary;
//         text-align: left;
//         border-radius: 12px;
//       }
//     }
//   }
// }

// .toolsContainer1 {
//   position: relative;
//   .searchBox1 {
//     position: absolute;
//     top: 20px;
//     left: 160px;
//     width: 37%;
//     input {
//       padding-left: 30px;
//       border-radius: 12px;
//       height: 50px;
//       &:focus {
//         box-shadow: none;
//       }
//     }
//     i {
//       position: absolute;
//       left: 10px;
//       top: 50%;
//       transform: translateY(-50%);
//       color: $dark;
//     }
//     .serchResults {
//       width: 100%;
//       height: auto;
//       background-color: $light;
//       margin-top: 10px;
//       border-radius: 15px;
//       position: absolute;
//       top: 50px;
//       overflow: auto;
//       z-index: 4;
//       // display: none;

//       .result {
//         @include itemPosition(center, flex-start);
//         gap: 10px;
//         padding: 10px;
//         cursor: pointer;
//         &:not(:last-child) {
//           border-bottom: 0.5px solid $grey;
//         }
//         .image {
//           width: 50px;
//           height: 50px;
//           border-radius: 5px;
//           img {
//             width: 100%;
//             border-radius: 5px;
//           }
//         }
//         .roundImage {
//           @include roundShape(55px, 55px, $primary);
//           // padding: 10px;
//           img {
//             width: 100%;
//             @include roundShape(50px, 50px, $primary);
//           }
//         }
//         .text {
//           p {
//             color: $dark;
//           }
//         }
//       }
//     }
//   }
//   .filters {
//     .card {
//       position: relative;
//       border-radius: 12px;
//       width: 100%;
//       i {
//         position: absolute;
//         top: 50%;
//         transform: translateY(-50%);
//         left: 10px;
//         color: $primary;
//       }
//       .p-multiselect {
//         padding-left: 20px;
//         border: none;
//         color: $primary;
//         text-align: left;
//         border-radius: 12px;
//       }
//     }
//   }
// }

.toolsContainer1 {
  position: relative;

  .searchBox1 {
    position: absolute;
    bottom: 220px;
    width: 64%;

    @media (max-width: 768px) {
      left: 54%;
      transform: translateX(-50%);
      width: 80%;
      top: -40px; /* Move the search bar up by 100px */
    }

    input {
      padding-left: 30px;
      border-radius: 12px;
      height: 50px;

      &:focus {
        box-shadow: none;
      }
    }

    i {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $dark;
    }

    .serchResults {
      width: 100%;
      height: auto;
      background-color: $light;
      margin-top: 10px;
      border-radius: 15px;
      position: absolute;
      top: 50px;
      overflow: auto;
      z-index: 4;

      .result {
        @include itemPosition(center, flex-start);
        gap: 10px;
        padding: 10px;
        cursor: pointer;

        &:not(:last-child) {
          border-bottom: 0.5px solid $grey;
        }

        .image {
          width: 50px;
          height: 50px;
          border-radius: 5px;

          img {
            width: 100%;
            border-radius: 5px;
          }
        }

        .roundImage {
          @include roundShape(55px, 55px, transparent);

          img {
            width: 100%;
            @include roundShape(50px, 50px, transparent);
          }
        }

        .text {
          p {
            color: $dark;
          }
        }
      }
    }
  }

  .filters {
    .card {
      position: relative;
      border-radius: 12px;
      width: 100%;

      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        color: $primary;
      }

      .p-multiselect {
        padding-left: 20px;
        border: none;
        color: $primary;
        text-align: left;
        border-radius: 12px;
      }
    }
  }
}

.toolsContainer2 {
  position: relative;

  .searchBox2 {
    position: absolute;
    top: -57px;
    right: 74px;
    width: 219px;
    input {
      padding-left: 30px;
      border-radius: 12px;
      height: 50px;
      &:focus {
        box-shadow: none;
      }
    }
    i {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $dark;
    }
    .serchResults {
      width: 100%;
      height: auto;
      background-color: $light;
      margin-top: 10px;
      border-radius: 15px;
      position: absolute;
      top: 50px;
      overflow: auto;
      z-index: 4;
      // display: none;

      .result {
        @include itemPosition(center, flex-start);
        gap: 10px;
        padding: 10px;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 0.5px solid $grey;
        }
        .image {
          width: 50px;
          height: 50px;
          border-radius: 5px;
          img {
            width: 100%;
            border-radius: 5px;
          }
        }
        .roundImage {
          @include roundShape(55px, 55px, transparent);
          // padding: 10px;
          img {
            width: 100%;
            @include roundShape(50px, 50px, transparent);
          }
        }
        .text {
          p {
            color: $dark;
          }
        }
      }
    }
  }
  .filters {
    .card {
      position: relative;
      border-radius: 12px;
      width: 100%;
      i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        color: $primary;
      }
      .p-multiselect {
        padding-left: 20px;
        border: none;
        color: $primary;
        text-align: left;
        border-radius: 12px;
      }
    }
  }
}

@media (max-width: 768px) {
  .toolsContainer2 .searchBox2 {
    right: 46%;
    transform: translateX(50%);
    width: 80%;
    top: -40px; /* Move the search bar up by 100px */
  }
}

@media (max-width: 758px) {
  .staffList {
    .staffProfileList {
      max-width: 290px;
    }
  }
}

@media (max-width: 758px) {
  .invitationRequest {
    .topBar {
      background-color: #f4f4f4;

      .content {
        @include itemPosition(center, flex-start);

        .heading {
          padding: 15px;
        }
      }
    }

    .tabs {
      @include itemPosition(center, flex-start);

      border-bottom: 2px solid $grey;

      padding: 20px 0;

      .tab {
        width: 200px;

        position: relative;

        text-align: center;

        // color: $grey;

        cursor: pointer;

        .line {
          height: 6px;

          background-color: transparent;

          position: absolute;

          bottom: -24px;

          width: 100%;

          border-radius: 5px;
        }

        &.active {
          .line {
            background-color: #faf14b;
          }
        }
      }
    }

    .tabContent {
      padding: 30px 0;

      .content {
        @include itemPosition(center, space-between);

        flex-wrap: wrap;

        .dates {
          @include itemPosition(center, flex-start);

          gap: 12px;

          flex-wrap: wrap;

          .datePicker {
            label {
              color: #f4f4f4;
            }
            @include itemPosition(center, flex-start);

            gap: 15px;

            input {
              border: 1px solid black;

              &:focus {
                box-shadow: none;
              }
            }
          }

          .dropdown {
            select {
              border: 1px solid #faf14b;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .searchStaff {
        margin: 20px 0;

        .searchBox {
          position: relative;

          input {
            background-color: $xlgrey;

            border: none;

            width: 100%;

            padding-left: 40px;

            &:focus {
              box-shadow: none;
            }
          }

          i {
            position: absolute;

            top: 50%;

            transform: translateY(-50%);

            left: 16px;
          }
        }
      }

      .staffProfileList {
        position: relative;

        border: 1px solid #faf14b;

        border-radius: 15px;

        background-color: $light;

        // padding: 10px;

        // margin: 10px 0;

        height: 200px;

        @include itemPosition(center, space-between);

        .firstdata {
          @include itemPosition(center, flex-start);

          gap: 15px;

          .image {
            @include roundShape(70px, 70px, transparent);

            padding: 5px;

            img {
              width: 100%;

              border-radius: 50%;

              height: 100%;
            }
          }

          .text {
            border-right: 1px solid #faf14b;

            p {
              max-width: 250px;

              font-size: 14px;

              color: $grey span {
                color: $dark;
              }
            }
          }
        }

        .secondData {
          @include itemPosition(center, flex-end);

          gap: 10px;

          position: absolute;

          bottom: -10px;

          margin-bottom: 14px;

          .link {
            a {
              text-decoration: none;

              color: #faf14b;
            }
          }
        }

        &:hover {
          background-color: $xlight;

          .text {
            p {
              a {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.file-input-profile {
  position: absolute;
  z-index: 111;
  opacity: 0;
  width: 30px;
  height: 30px;
}

.notification-badge-admin {
  position: absolute;
  top: 5px;
  right: 22px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.admin-notification-menu {
  height: 300px;
  // width: 300px;
  overflow-y: auto;
}
.admin-notification-message-container {
  width: auto;
  display: flex;
}

.admin-notification-message {
  width: auto;
  white-space: normal;
}

a {
  cursor: pointer;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.center h2 {
  margin-bottom: 2rem;
}

.center p {
  margin: 0 auto;
  max-width: 800px; /* Adjust this value to control the maximum width of the content */
}

.img-fluid {
  max-width: 100px;
}
// new scss start here
.btn-height {
  background-color: #faf14b !important;
  color: #0c0d0a !important;
  border: 1px solid black !important;
  font-family: "Aeonik-Bold";
  // width: 100%;
  border-radius: 20px;
  &:hover {
    background-color: #faf14b;
  }
}
.homeButton {
  width: 100% !important;
  padding: 24px 32px;
  gap: 24px;
  @media screen and (max-width: 576px) {
    padding: 24px 24px;
    gap: 0px;
  }

  h2 {
    color: var(--Brand-black, #0e0e0e);
    /* Desktop/Buttons Landing */
    // font-family: Spoof Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 89%; /* 35.6px */
    letter-spacing: -2px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 576px) {
    h2:nth-child(1) {
      color: var(--Brand-black, #0e0e0e);
      /* Mobile/Buttons Landing */
      // font-family: Spoof Trial;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 90%; /* 18px */
      letter-spacing: -1px;
      text-transform: uppercase;
      flex: 1 0 0;
    }
    h2:nth-child(2) {
      font-size: 30px;
    }
  }

  &:hover {
    background-color: #0e0e0e !important;
    border: 2px solid #faf14b !important;
    color: #faf14b !important;
    h2 {
      color: #faf14b !important;
      /* Desktop/Buttons Landing */
      // font-family: Spoof Trial;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 89%; /* 35.6px */
      letter-spacing: -2px;
      text-transform: uppercase;
    }
  }
}
.tipButtons:hover {
  background-color: transparent !important;
  border: 2px solid #faf14b !important;
  color: #f4f4f4 !important;
}
.btn-height:hover {
  color: #0c0d0a;
}
.paddingAll {
  padding: 24px;
}

.blackBorder {
  border: 1px solid #0c0d0a !important;
}
.paddingBottom0 {
  padding-bottom: 32px !important;
}
.marginNone {
  margin: 0px !important;
}
.whiteText {
  color: #f4f4f4 !important;
}
.whiteTexts {
  color: #f4f4f4 !important;
}

@media screen and (max-width: 576px) {
  .whiteTexts {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.darkBackground {
  background-color: #0c0d0a !important;
  margin: 0px !important;
}
.whiteBackground {
  background-color: #f5f5f5 !important;
}
.dflex {
  display: flex;
}
.alc {
  align-items: center;
}
.paddingNone {
  padding-top: 0px !important;
}
.paddingTop {
  padding-top: 30px !important;
}
.paddingBottom {
  padding-bottom: 30px !important;
}
.text-end {
  text-align: end;
}
.padding-x {
  padding-left: 20px;
  padding-right: 20px;
}
.jcs {
  justify-content: start !important;
}

.borderNone {
  border: 0px !important;
}
.socialMedia button:nth-child(1) {
  // color: red !important;
  background-color: #1777f2 !important;
  span {
    color: #f4f4f4;
  }
}
body {
  background: #0c0d0a;
}
.jce {
  // justify-content: end;
  justify-content: flex-end;
}
.ale {
  // align-items: end;
  align-items: flex-end;
}
.borderBottom {
  border-bottom: 1px solid #0c0d0a !important;
}
.borderBottom1 {
  border-bottom: 2px solid #0c0d0a !important;
}
.blackBoder {
  border: 1px solid #0c0d0a;
  border-radius: 20px;
  padding: 20px;
}
.padding-xt {
  padding-left: 20px !important;
  padding-right: 35px !important;
}
.inputTextColor {
  color: var(--Brand-black, #0e0e0e);
  font-feature-settings: "clig" off, "liga" off;
  /* Desktop/Caption */
  padding-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.noDecoration {
  text-decoration: none;
}
.paddingLeft {
  padding-left: 38px !important;
}
.padding-y {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.Aeonik-Regular {
  font-family: "Aeonik-Regular" !important;
}
.Aeonik-Bold {
  font-family: "Aeonik-Bold" !important;
}
.Spoof-Black {
  font-family: "Spoof Black" !important;
}
label {
  font-family: "Aeonik-Regular" !important;
}
.table-wraper .table-responsive table.table thead th {
  font-family: "Aeonik-Bold" !important;
}
hr {
  background-color: #0e0e0e !important;
  height: 2px !important;
  opacity: 1 !important;
}
.accountButton {
  background-color: #f4f4f4 !important;
  color: var(--Brand-black, #0e0e0e);
  gap: 24px;
  @media screen and (max-width: 576px) {
    min-width: 100%;
    h2 {
      color: var(--Brand-black, #0e0e0e);
      /* Mobile/Buttons Landing */
      // font-family: Spoof Trial;
      font-size: 20px !important;
      font-style: normal;
      font-weight: 800;
      line-height: 90%; /* 18px */
      letter-spacing: -1px;
      text-transform: uppercase;
    }
  }

  /* Desktop/Buttons Landing */
  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 89%; /* 35.6px */
    letter-spacing: -2px;
    text-transform: uppercase;
  }
  &:hover {
    background-color: #0e0e0e !important ;
    color: #f4f4f4 !important;
  }
}
.qrScan {
  color: var(--Brand-black, #0e0e0e);
  /* Desktop/Body/B3 */
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 150% !important; /* 27px */
  opacity: 0.5;
  padding: 0px 24px;
}
.table-wraper .table-responsive table.table td {
  color: #0c0d0a !important;
  font-family: "Aeonik-Regular" !important;
}
.notification-dropdown {
  background-color: #faf14b !important;
  color: #0c0d0a !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.localFind {
  color: var(--Brand-black, #0e0e0e);

  /* Desktop/Headers/H2 */
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 80%; /* 32px */
  letter-spacing: -2px;
  flex: 1 0 0;
  @media screen and (max-width: 576px) {
    color: var(--Brand-black, #0e0e0e);
    /* Mobile/HEADERS/H1 */
    // font-family: Spoof Trial;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 90%; /* 21.6px */
    letter-spacing: -1.2px;
    text-transform: uppercase;
  }
}

.responsiveFlex {
  @media screen and (max-width: 576px) {
    display: flex;
  }
}
// .container1{
//   max-width: 1440px !important;
// }
.staffDashbord1 {
  // padding-top:20px;
}
.backgroundYellow {
  background-color: #faf14b !important;
}

@media (min-width: 1440px) {
  .container-fluid {
    width: 100%;
  }
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1440px;
}

.padding-x10 {
  padding: 0px 8px;
}
.employerButton {
  width: 60% !important;
}
.roundImage {
  border-radius: 50% !important;
  width: 58px !important;
  height: 58px !important;
}
.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}
.borderRight {
  border-right: 1px solid rgba(5, 5, 5, 0.9);
}
.nextBtn1 {
  @include roundShape(25px, 25px, black);
  color: $light;
  i {
  }
}
.fa-chevron-right {
  font-size: 35px !important;
}

@media only screen and (max-width: 576px) {
  .mobiledisplayNone {
    display: none !important;
  }
  .mobileDisplay {
    img {
      display: block !important;
      width: 100% !important;
    }
  }
  .profileview {
    border-left: 0px !important;
  }
}
@media only screen and (min-width: 576px) {
  .mobileDisplay {
    img {
      display: none !important;
    }
  }
}
@media only screen and (max-width: 576px) {
  .staffProfileList .firstdata .image {
    width: 100px;
    height: 63px;
  }

  .page-item:not(:first-child) .page-link {
    margin-left: -4px;
  }
  .mobilePaddingLeftNone {
    padding-left: 0px !important;
  }
  .mobilePaddingRightNone {
    padding-right: 0px !important;
  }
  .fitContent {
    width: 100%;
  }
  .form-select {
    font-size: 14px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .mobileBorderBottom {
    border-bottom: 1px solid rgba(244, 244, 244, 0.2);
  }
  .mobileFooterButton {
    padding: 10px !important;
  }
  .mobileAskaway {
    color: var(--Brand-black, #0e0e0e);

    font-size: 24px !important;
    font-style: normal !important;
    font-weight: 800 !important;
    line-height: 90% !important;
    letter-spacing: -1.2px !important;
    text-transform: uppercase !important;
  }
  .navbar-nav {
    flex-direction: row !important;
  }
  .mobileBorderNone {
    border: 0px !important;
  }
  .mobileResponsivePadding {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mobileNocenter {
    justify-content: start;
  }
  .mobilepaddingBottom {
    padding-bottom: 10px;
  }
  .pubsDetail {
    p {
      align-self: stretch;
      color: var(--Brand-black, #0e0e0e);
      /* Mobile/Body/B1 */
      // font-family: Aeonik;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 18px */
    }
  }
  .mobileGrid {
    display: grid;
  }
  .mobileViewImages {
    height: 150px !important;
  }

  #AccountCreate {
    h3 {
      color: var(--Brand-black, #0e0e0e);
      /* Mobile/HEADERS/H1 */
      // font-family: Spoof Trial;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 90%; /* 21.6px */
      letter-spacing: -1.2px;
      text-transform: uppercase;
    }
  }
  .paddingLeft-8 {
    padding-left: 4px !important;
  }
  .mobilePaddingNone {
    padding: 0px;
  }
  .text-start {
    text-align: start !important;
  }
  .p2 {
    margin-left: 5px !important;
  }
  .mobileView {
    display: block !important;
  }
  .webView {
    display: none !important;
    height: unset !important;
  }
  .mobilePading {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .paddingRight-8 {
    padding-right: 4px !important;
  }
  .mobileNavBar {
    padding-left: 6px;
    padding-right: 6px;
  }
  // .facebookButton {
  //   height: 62px !important;
  // }
}
@media only screen and (min-width: 577px) {
  .mobileView {
    display: none !important;
  }
  .webView {
    display: block !important;
  }
}
.smallText {
  font-size: 20px !important;
}
.cursorPointer {
  cursor: pointer;
}
.deleteIcon {
  color: #f4f4f4;
}
.blackBorder {
  .form-select:focus {
    border-color: #f4f4f4 !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
}
.wordBreak {
  word-break: keep-all;
  white-space: nowrap;
  // width: 97%;
  height: 50px;
  font-size: 14px;
}

.imageTouse {
  width: 600px !important;
}
.whiteBackground {
  background-color: #f4f4f4 !important;
}
.Whitebackground {
  background-color: white !important;
}
.gap {
  gap: 80px;
}
.signinLink {
  color: var(--Brand-black, #0e0e0e);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.mobileSignInButton {
  padding-left: 16px;
  padding-right: 16px;
}
.Toastify__progress-bar--success {
  background: #faf14b !important;
}
.Toastify__toast-icon {
  svg {
    fill: #faf14b !important;
  }
}
.customCard {
  height: 2%;
  width: 15%;
  padding: 10px;
  background: #161616 !important;
  .inputVal {
    max-width: 80px;
  }
}
.customContainer {
  padding: 10px;
  display: flex;
  height: 30%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #161616 !important;
}
.containerVal {
  padding: 20px;
  display: flex;
  margin: 10px;
  // height: 15%;
  display: flex;
  // width: 20%;
  flex-wrap: wrap;
  border-radius: 10px;
  background: #ffffff !important;
}
.item {
  padding: 10px;
  border-radius: 10px;
  background: #ffffff !important;
}
.roundShapeProfile {
  img {
    border-radius: 50%;
    width: 230px !important;
    height: 230px;
  }
}
.mainDivofProfile {
  padding-left: 0px;
  .steps {
    display: flex;
    // justify-content: center;
    @media only screen and (max-width: 768px) {
      justify-content: start;
      p {
        margin-top: 10px !important;
      }
    }
    .nav-link {
      padding: 0px !important;
    }
  }
  .roundShapeStaffProfile {
    align-items: center;
    overflow: hidden;
    .image {
      /* align-items: center; */
      // display: flex;
      // justify-content: center;
      padding: 0px 15px;
      flex-wrap: wrap;

      @media only screen and (max-width: 767px) {
        // width: 50%;
        display: inline-block;
        padding: 0px 30px;

        image {
          padding: 0px;
        }
      }
      @media only screen and (max-width: 576px) {
        // width: 50%;
        display: inline-block;
        padding: 0px 10px;

        image {
          padding: 0px;
        }
      }

      img {
        @media only screen and (max-width: 767px) {
          // display: inline-block !important;
          width: 100px;
          height: 100px;
        }
        @media only screen and (max-width: 576px) {
          // display: inline-block !important;
          width: 60px;
          height: 60px;
        }
        width: 160px;
        height: 160px;
        margin-left: 8px;
        border-radius: 50%;
        display: flex;
      }
    }
  }
}

.remove_img {
  background-color: bisque;
  border-radius: 69%;
  border: 1px solid #7a7a7a;
  position: relative;
  top: -67px;
  right: -39px;
  padding: 4px 8px;
  font-size: 11px;
  cursor: pointer;
  z-index: 1;
}
.remove_img1 {
  background-color: bisque;
  border-radius: 69%;
  border: 1px solid #7a7a7a;
  position: relative;
  top: 10px;
  right: 0px;
  padding: 4px 8px;
  font-size: 11px;
  cursor: pointer;
  z-index: 1;
}
.whiteHr {
  background-color: white !important;
}
.googleButton {
  button {
    border-radius: 20px !important;
    padding: 0px 8px 0px 8px !important;
    @media screen and (max-width: 576px) {
      width: 70% !important;
    }
    div {
      padding: 0px 18px 0px 18px !important;
      margin: 0px;
    }
  }
  div {
    background-color: transparent !important;
  }
  span {
    font-size: 15px;
    font-weight: 700 !important;
  }
}
.staffUpdate {
  img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 1px solid black;
  }
  .quickUpdate {
    button {
      border-radius: 20px;
      border: none;
      height: 50px;
      width: 100%;
      background-color: #ccc3c3;
    }
  }
}
.post {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 1px solid black;
}
.quickupdateText {
  padding: 0px 20px 0px 20px;

  .quickupdateInnerdiv {
    border-radius: 20px;
    border: 1px solid lightgray;
    background-color: #f7f7f7;
    div {
      display: flex;
      // justify-content: end;
      justify-content: flex-end;
      .postButton {
        width: 300px;
      }
    }
  }
  textarea {
    background-color: transparent;
    padding: 15px;
    width: 100%;
    border: 0px;
    &:focus-visible {
      outline: #1777f2;
    }
  }
}
.imageButton {
  margin-top: 20px;
  width: 100%;
}
.imageuploader {
  padding: 20px;
  .addImage {
    border: 1px dashed gray;
    .addimagemultiple {
      // padding: 2px;

      .UploadBox {
        border: 1px dashed gray;
        border-radius: 20px;
        width: 120px;
        height: 120px;
        padding-top: 20px;
      }
    }
  }
}
.orhr {
  position: relative;
  margin-top: 25px;
  padding: 0 30px;

  p.orText {
    position: absolute;
    background-color: #f4f4f4;
    padding: 10px;
    left: 50%;
    transform: translateX(-50%);
    top: -22px;
  }
}

.pubstarAbout {
  ::-webkit-scrollbar-thumb {
    background: transparent !important;
  }
  ::-webkit-scrollbar {
    width: 0px !important;
  }
  ::-webkit-scrollbar-track {
    background: var(--Divide-stroke, transparent) !important;
  }
  .text {
    font-weight: 700;
    font-size: 16px;
  }
  .update {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .about {
    padding: 20px;
    .UploadBox {
      max-width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        i {
          font-size: 30px;
        }
      }
      .text {
        font-size: 30px;
      }
    }

    .galleryText {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .gallery {
      position: relative;
      .multiImageInfo1 {
        border-radius: 4px;
        padding: 2px 6px;
        color: #fff;
        top: 8px;
        right: 48px;
        position: absolute;
        font-weight: 600;
        z-index: 5;
        // pointer-events: none;
        background-color: rgba(0, 0, 0, 0.8);
      }
      .multiImageInfo2 {
        border-radius: 4px;
        padding: 2px 6px;
        color: #fff;
        top: 8px;
        right: 10px;
        position: absolute;
        font-weight: 600;
        z-index: 5;
        // pointer-events: none;
        background-color: rgba(0, 0, 0, 0.8);
      }
      img {
        max-width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .pubstarProfile {
      .profileImg {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
          border: 3px solid #faf14b;
        }
      }
    }
    .speech {
      /* (A1) FONT & COLORS */
      font-size: 1.2em;
      // border: 1px solid gray;
      /* (A2) DIMENSIONS */
      // padding: 5px 0px;
      border-radius: 10px;
      .caption {
        padding: 0px 20px 0px 20px;
      }

      .descr {
        white-space: pre-line;
      }
      .imageView {
        max-width: 100%;
        position: relative;
        .multiImageInfo {
          border-radius: 4px;
          padding: 2px 6px;
          color: #fff;
          top: 8px;
          left: 8px;
          position: absolute;
          font-weight: 600;
          z-index: 5;
          pointer-events: none;
          background-color: rgba(0, 0, 0, 0.8);
        }

        img {
          max-height: 460px;
          object-fit: contain;
          height: auto;
          max-width: 100%;
          width: 100%;
        }
      }
    }
    // .speech::after {
    //   display: block;
    //   width: 0;
    //   content: "";
    //   border: 15px solid transparent;
    //   border-style: solid;
    // }

    /* (C) "CALLOUT" DIRECTIONS */
    // .speech.up::after {
    //   border-bottom-color: gray;
    //   border-top: 0;
    // }
    // .speech {
    //   position: relative;
    //   margin: 20px 0px 20px 0px;
    // }
    // .speech::after {
    //   position: absolute;
    // }
    // .speech.up::after {
    //   top: -15px;
    //   left: calc(8% - 15px);
    // }
  }
}
.likeSection {
  padding: 15px;
  display: flex;
  .editPost {
    .dropdown-toggle::after {
      display: none;
    }
  }
}
.comentSection {
  .borderallSide {
    border: 1px solid black;
    border-radius: 10px;
  }
  padding: 0px 20px;
  .comment {
    .inputField {
      .inputBox {
        border: 0;
      }
    }
  }
  .replyButton {
    background-color: transparent;
    border: 2px solid transparent;
    padding: 4px 0px;
    color: gary;
  }
}
.titleBox {
  .inputField .inputBox input {
    background-color: white !important;
  }
}

// webView
.row.ProfileDesign.mypub .mainImage {
  position: static !important;
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  height: 100%;
}

.row.ProfileDesign.mypub .col-lg-4 {
  height: 300px;
  width: 32%;
  overflow: hidden;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.row.ProfileDesign.mypub .restaurenDetail {
  @media only screen and (max-width: 576px) {
    height: fit-content !important;
  }
}

hr {
  background-color: #0e0e0e21 !important;
  height: 1px !important;
  opacity: 1 !important;
}

// .staffProfile .staffPostData .profileContent .image {
//   width: 150px !important;
//   height: 150px !important;
//   @media screen and (max-width: 576px) {
//     width: 90px !important;
//     height: 90px !important;
//   }
// }

.staffProfile .staffPostData .profileContent .image {
  width: 150px;
  height: 150px;
}

/* Media query for smaller screens */
@media screen and (max-width: 576px) {
  .staffProfile .staffPostData .profileContent .image {
    width: 90px;
    height: 90px;
  }
}

.staffPostData {
  // background-color: transparent !important;
  // border: 1px solid #faf14b;
  margin-bottom: 0px !important;
  @media screen and (max-width: 576px) {
    margin-bottom: 0px !important;
  }

  // color: white !important;
}
.PhoneInput :focus-visible {
  outline: aliceblue;
}
.inputField1 {
  margin: 15px 0;
  label {
    margin-bottom: 10px;
  }
  .inputBox {
    position: relative;
    border: 1px solid #0c0d0a;
    border-radius: 7px;
    .form-control {
      border: 0px;
      background-color: #f4f4f4 !important;
    }
    input {
      background-color: #f4f4f4 !important;
      border-radius: 7px;
      border: none;
      padding: 0px 8px;
    }
  }
}

@media screen and (max-width: 576px) {
  .galleryGap {
    gap: 15px !important;
  }
}

.restroPublicprofile {
  @media screen and (max-width: 576px) {
    padding-left: 50px !important;
  }
}

.image-containers {
  display: flex;
}

@media screen and (max-width: 576px) {
  .scroll {
    overflow-x: auto;
    white-space: nowrap;
  }
}

.customToast {
  bottom: 2%;
  right: 2%;
  position: FIXED;
}

@media screen and (max-width: 576px) {
  .TOTAL {
    padding-left: 10px !important;
    padding-right: 9px !important;
  }
}

.delete_save_flx {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.nav-link.nav_desk {
  display: none;
}
.serach_pub_slider {
  display: none;
  .carousel-control-next-icon {
    right: 32px;
    position: absolute;
    opacity: 1;
    background-color: black;
    border-radius: 50%;
    background-size: 100% 63%;
    opacity: 1;
  }
  .carousel-control-prev-icon {
    left: 32px;
    position: absolute;
    opacity: 1;
    background-color: black;
    border-radius: 50%;
    background-size: 100% 63%;
    opacity: 1;
  }
}
#contactUs {
  img.contactUs {
    max-width: unset;
    width: 100%;
    position: unset;
  }
}
.header.without_login ul.navbar-nav {
  padding-right: 0 !important;
  border-right: none !important;
  gap: 32px;
}
.header.without_login {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.leave_some_love {
  color: white;
  font-family: "Spoof Black";
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.mobile_logo_login {
  margin-top: 30px;
  display: none;
}
p.favbartender.qrScan.webView {
  background-color: #faf14b !important;
  color: #000000 !important;
  opacity: 1;
  padding: 12px 10px;
  text-align: center;
  border-radius: 70px;
  display: inline-block !important;
  max-width: 480px;
  width: 100%;
}
.inputSearchBar {
  max-width: 480px;
  width: 100%;
}
.inputSearchBar .inputField.searchBix {
  width: 100%;
}
.report_tabs {
  overflow-x: auto;
  overflow-y: hidden;
}
.report_tabs {
  .tab {
    white-space: nowrap;
  }
}
.net_pay_able {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

// .
@media screen and (max-width: 991px) {
  .nav-link.nav_desk {
    display: block;
  }
  .profileImage.dropdown {
    display: none !important;
  }
  ul.navbar-nav li.nav-item {
    text-align: right;
  }
  .header.without_login {
    padding-right: 10px !important;
    padding-left: 10px !important;
    padding-bottom: 0 !important;
  }
  .without_login .container {
    padding-left: 0;
    padding-right: 0;
  }
  .report_tabs .tab {
    padding: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .login_head_txt {
    display: none;
  }
  .mobile_logo_login {
    display: block;
  }
  .mobile_logo_login img {
    width: 160px;
  }
}
@media screen and (max-width: 576px) {
  .how_it_work_head h3 {
    padding-left: 10px;
  }
  .staffSearch .getstarted .btn-height {
    height: unset;
  }
  .footerSection .mainButtondiv {
    flex-wrap: wrap;
    gap: 12px;
  }
  .footerSection .footerButton.btn-main.mobileFooterButton {
    flex: 0 0 calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .footerSection .footerButton.btn-main.mobileFooterButton:nth-child(odd) {
    border-left: none !important;
    border-right: 1px solid #fff !important;
    border-radius: 0;
  }
  .footerSection .footerButton.btn-main.mobileFooterButton:nth-child(even) {
    border-right: none !important;
    border-left: none !important;
  }
  .tipsRecieved.tip_recieved {
    flex-direction: column;
    gap: 10px;
  }
  .tipsRecieved.tip_recieved .col-4 {
    flex: 1;
    width: 100%;
    padding: 0 6px !important;
  }
  .tipsRecieved.tip_recieved .totalRecievedAmount {
    font-size: 16px !important;
  }
  .delete_save_flx {
    display: flex;
    flex-direction: column-reverse;
    gap: 0;
  }
  .delete_save_flx button {
    flex: 1 0 0;
  }
  .profileSettingConent .inputField label,
  .profileSettingConent .inputField label button {
    width: 100%;
  }
  .pay_ment_card h3.Aeonik-Regular {
    text-align: center;
  }
  .pay_ment_card_cntnt {
    flex-direction: column;
    gap: 18px;
  }
  .pay_ment_card_btn h3 {
    text-align: center;
  }
  .pay_ment_card_btn,
  .pay_ment_card_btn button {
    width: 100%;
  }
  .contactSection_btn {
    text-align: right;
  }
  .header .webView {
    width: 120px !important;
    display: block !important;
    height: unset !important;
  }
  .hospoStars.newStars .starContent {
    padding: 16px 15px;
    height: unset;
  }
  .hospoStars.newStars .starContent p.blackText.Aeonik-Regular {
    margin-bottom: 0;
  }
  .hospoStars.newStars .starContent .profile {
    // padding-top: 0;
    width: 100px;
    height: 100px;
  }
  .hospoStars.newStars .starContent .profile img {
    object-fit: cover;
  }
  li.nav-item.hide_mobile {
    display: none;
  }
  .serach_pub_slider {
    display: block;
  }
  .serach_pub_slider_mobile {
    display: none;
  }
  .leave_some_love {
    font-size: 24px;
  }
  .mobile_tab_content {
    display: none;
  }
  .facebookButton span {
    font-size: 13px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  p.favbartender.qrScan.webView {
    display: none !important;
  }
  .pubname_start {
    text-align: left !important;
    padding-bottom: 0 !important;
  }
  .pubname_start p {
    margin-bottom: 0;
  }
  .tipping_pay_method {
    text-align: center;
  }
  .net_pay_able {
    flex-direction: column;
  }
  .tipping_pay_method .card .row {
    justify-content: center;
  }
  .tipping_pay_method button {
    width: 100%;
  }
  .serach_pub_slider.serach_pub_slider_two {
    .carousel-control-next-icon {
      right: 20px;
    }
    .carousel-control-prev-icon {
      left: 20px;
    }
  }
  section.footerSection .footerLogo {
    justify-content: center;
  }
  .hospo_Stars {
    padding: 0 10px 0 15px;
  }
}


/* Aligns the buttons with space between and adds padding */
.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Space between buttons */
  padding: 20px;
}

/* Styles the select dropdown */
.country-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

/* Specific button styles for consistency */
// .btn-logout {
//   background-color: #faf14b;
//   color: black;
//   border: none;
//   padding: 8px 16px;
//   border: 1px solid black;

// }

.btn-submit {
  background-color: #faf14b;
  color: black;
  border: 1px solid black;
  padding: 8px 16px;
  display: block;
  margin: auto;
}

.form-control:focus {
  outline: none; /* Removes the focus outline */
  border: none; /* Removes the focus border */
  box-shadow: none; /* Removes any shadow applied on focus */
}

.payment-font{
  font-size: 20px;
  padding-left: 20px;
  padding-top: 12px;
}

.img-fit{
  object-fit: cover;
}

.eye-icon {
    position: absolute;
    right: 10px;
    top: 12px;
    color: #6c757d;
    font-size: 16px;
    z-index: 1;
}