.progress_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1px 2px;
    margin-bottom: 2px;   
}

.progress_track {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #0c0d0a;
    margin-bottom: 15px;
}

.progress {
    height: 100%;
    border-radius: 10px;
}