
  @media screen and (max-width: 475px ) {
    .tippingImage .about .tippingTime{
      line-height: 28% !important;

    }
  
      .tippingImage .about .tippingTime svg{
      position: relative!important;
      left: -8px !important;
     top: -10px !important;
      
      
    }




}